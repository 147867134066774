var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import styles from './PencilBanner.style';
import RichText from '../../atoms/RichText/RichText';
var PencilBanner = function PencilBanner(_ref) {
  var bannerData = _ref.bannerData,
    className = _ref.className;
  var message = bannerData.message;
  var bannerMessage = message ? message.json : '';
  var anchorLink = bannerData && (bannerData === null || bannerData === void 0 ? void 0 : bannerData.anchorLink) || '';
  return __jsx("div", {
    className: className,
    id: anchorLink
  }, __jsx(RichText, {
    ContainerElem: "div",
    jsonContent: bannerMessage,
    className: "container"
  }));
};
PencilBanner.defaultProps = {
  className: ''
};
export default styled(PencilBanner).withConfig({
  componentId: "sc-143ytcg-0"
})(["", ";"], styles);