import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["className", "variation", "burgerToggle", "isBurgerOpen"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import Anchor from '../Anchor';
import styles from './Hamburger.style';
import Close from '../../../static/images/icons/Close';
var Hamburger = function Hamburger(_ref) {
  var className = _ref.className,
    variation = _ref.variation,
    burgerToggle = _ref.burgerToggle,
    isBurgerOpen = _ref.isBurgerOpen,
    others = _objectWithoutProperties(_ref, _excluded);
  return __jsx(Anchor, _extends({
    title: "navbar toggler",
    noLink: true,
    handleLinkClick: burgerToggle,
    className: "navbar-toggler ".concat(className)
  }, others), !isBurgerOpen && __jsx("div", {
    className: "hamburger-open"
  }, __jsx("span", {
    className: "bars"
  }, __jsx("span", null), __jsx("span", {
    className: "center"
  }), __jsx("span", null))), isBurgerOpen && __jsx(Close, {
    className: "close-button"
  }));
};
Hamburger.defaultProps = {
  className: ''
};
export default styled(Hamburger).withConfig({
  componentId: "sc-1d0liqw-0"
})(["", ";"], styles);
export { Hamburger as HamburgerVanilla };