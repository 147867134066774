/**
 * @file Manages styles for Address atom.
 * @author kkumari
 */
import { css } from 'styled-components';
import breakPoints from '../../../themes/breakpoints';
var styles = css(["font-weight:normal;color:", ";font-family:", ";font-size:", ";line-height:1.3;letter-spacing:", ";font-style:normal;margin-bottom:10px;.addressLine1,.addressLine2{display:block;}.postalCode{padding-left:0.5em;}@media (min-width:", "){font-size:16px;letter-spacing:", ";}"], function (props) {
  var _props$theme, _props$theme$address;
  return (_props$theme = props.theme) === null || _props$theme === void 0 ? void 0 : (_props$theme$address = _props$theme.address) === null || _props$theme$address === void 0 ? void 0 : _props$theme$address.addressColor;
}, function (props) {
  var _props$theme2;
  return (_props$theme2 = props.theme) === null || _props$theme2 === void 0 ? void 0 : _props$theme2.fontFamilyP;
}, function (props) {
  var _props$theme3;
  return (_props$theme3 = props.theme) === null || _props$theme3 === void 0 ? void 0 : _props$theme3.fontSizeP;
}, function (props) {
  var _props$theme4;
  return (_props$theme4 = props.theme) === null || _props$theme4 === void 0 ? void 0 : _props$theme4.letterSpacingP;
}, breakPoints.desktop, function (props) {
  var _props$theme5, _props$theme5$desktop;
  return (_props$theme5 = props.theme) === null || _props$theme5 === void 0 ? void 0 : (_props$theme5$desktop = _props$theme5.desktop) === null || _props$theme5$desktop === void 0 ? void 0 : _props$theme5$desktop.letterSpacingP;
});
export default styles;