import { css } from 'styled-components';
import breakPoints from '../../../themes/breakpoints';
var styles = css(["&.global-header{display:flex;flex-flow:row nowrap;justify-content:flex-start;}.main-container{display:flex;flex-direction:column;border-bottom:1px solid ", ";background-color:", ";@media (min-width:", "){flex-direction:row;position:relative;}}.header-container{display:flex;flex-flow:column nowrap;justify-content:center;align-items:center;position:relative;border-bottom:1px solid ", ";@media (min-width:", "){justify-content:space-between;border-bottom:none;}}&.logo-center{.header-container{@media (min-width:", "){position:absolute;left:", ";top:", ";}@media (min-width:", "){top:", ";}}.nav-contacts,.nav-utility{@media (min-width:", "){max-width:", ";}@media (min-width:", "){max-width:", ";}}}.navbar-collapse{display:none;@media (min-width:", "){display:flex;align-items:flex-start;flex-direction:column;width:100%;}}.navbar-nav{display:flex;flex-direction:column;@media (min-width:", "){flex-direction:row;align-items:center;}}&.burger-open{.navbar-collapse{display:flex;flex-direction:column;}}.multi-location-cta{display:none;@media (max-width:", "){display:block;width:100%;margin:10px auto;padding-bottom:10px;border-bottom:1px solid #eaeaea;a{width:95%;margin:auto;display:flex;justify-content:space-between;}.svg-search-icon{width:1.5em;height:1.3em;margin-right:10px;}.svg-search-icon path,.svg-search-icon polygon,.svg-search-icon rect{fill:", ";}.svg-search-icon circle{stroke:", ";stroke-width:2;}}}"], function (props) {
  var _props$theme;
  return (_props$theme = props.theme) === null || _props$theme === void 0 ? void 0 : _props$theme.header.borderColor;
}, function (props) {
  var _props$theme2;
  return (_props$theme2 = props.theme) === null || _props$theme2 === void 0 ? void 0 : _props$theme2.header.bgColor;
}, breakPoints.desktop, function (props) {
  var _props$theme3;
  return (_props$theme3 = props.theme) === null || _props$theme3 === void 0 ? void 0 : _props$theme3.header.borderColor;
}, breakPoints.desktop, breakPoints.desktop, function (props) {
  var _props$theme4;
  return (_props$theme4 = props.theme) !== null && _props$theme4 !== void 0 && _props$theme4.header.displayLargerLogo ? '40%' : '43%';
}, function (props) {
  var _props$theme5;
  return (_props$theme5 = props.theme) !== null && _props$theme5 !== void 0 && _props$theme5.header.displayLargerLogo ? '4px' : '10px';
}, breakPoints.desktopM, function (props) {
  var _props$theme6;
  return (_props$theme6 = props.theme) !== null && _props$theme6 !== void 0 && _props$theme6.header.displayLargerLogo ? '3px' : '6px';
}, breakPoints.desktop, function (props) {
  var _props$theme7;
  return (_props$theme7 = props.theme) !== null && _props$theme7 !== void 0 && _props$theme7.header.reduceNavContactsWidth ? '25%' : '40%';
}, breakPoints.desktopM, function (props) {
  var _props$theme8;
  return (_props$theme8 = props.theme) !== null && _props$theme8 !== void 0 && _props$theme8.header.reduceNavContactsWidth ? '30%' : '45%';
}, breakPoints.desktop, breakPoints.desktop, breakPoints.tabletL, function (props) {
  var _props$theme9;
  return (_props$theme9 = props.theme) === null || _props$theme9 === void 0 ? void 0 : _props$theme9.button.linkColorPrimary;
}, function (props) {
  var _props$theme10;
  return (_props$theme10 = props.theme) === null || _props$theme10 === void 0 ? void 0 : _props$theme10.button.linkColorPrimary;
});
export default styles;