import { css } from 'styled-components';
var SocialIconStyles = css(["height:30px;width:30px;position:relative;border-radius:100%;background-color:", ";display:inline-block;&:not(:last-of-type){margin-right:20px;}svg{fill:", ";vertical-align:middle;display:inline-block;height:15px;max-width:15px;position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);}a{width:100%;height:100%;text-align:center;position:relative;border-radius:100%;", ";&:hover{border-radius:100%;background-color:", ";", ";", ";svg{fill:", ";}}}"], function (props) {
  var _props$theme, _props$theme2;
  return (_props$theme = props.theme) !== null && _props$theme !== void 0 && _props$theme.socialIcon.iconColor ? (_props$theme2 = props.theme) === null || _props$theme2 === void 0 ? void 0 : _props$theme2.socialIcon.iconColor : 'transparent';
}, function (props) {
  var _props$theme3;
  return (_props$theme3 = props.theme) === null || _props$theme3 === void 0 ? void 0 : _props$theme3.socialIcon.fillColor;
}, function (props) {
  var _props$theme4, _props$theme5;
  return (_props$theme4 = props.theme) !== null && _props$theme4 !== void 0 && _props$theme4.socialIcon.iconBorderColor ? "\n      border-width: 1px;\n      border-style: solid;\n      border-color: ".concat((_props$theme5 = props.theme) === null || _props$theme5 === void 0 ? void 0 : _props$theme5.socialIcon.iconBorderColor, ";\n        ") : '';
}, function (props) {
  var _props$theme6;
  return (_props$theme6 = props.theme) === null || _props$theme6 === void 0 ? void 0 : _props$theme6.socialIcon.iconHoverColor;
}, function (props) {
  var _props$theme7, _props$theme8;
  return (_props$theme7 = props.theme) !== null && _props$theme7 !== void 0 && _props$theme7.socialIcon.iconBorderHoverColor ? "\n        border-color: ".concat((_props$theme8 = props.theme) === null || _props$theme8 === void 0 ? void 0 : _props$theme8.socialIcon.iconBorderHoverColor, ";\n          ") : '';
}, function (props) {
  var _props$theme9, _props$theme10;
  return (_props$theme9 = props.theme) !== null && _props$theme9 !== void 0 && _props$theme9.socialIcon.opacity ? "\n        opacity: ".concat((_props$theme10 = props.theme) === null || _props$theme10 === void 0 ? void 0 : _props$theme10.socialIcon.opacity, ";\n          ") : '';
}, function (props) {
  var _props$theme11, _props$theme12, _props$theme13;
  return (_props$theme11 = props.theme) !== null && _props$theme11 !== void 0 && _props$theme11.socialIcon.fillHoverColor ? (_props$theme12 = props.theme) === null || _props$theme12 === void 0 ? void 0 : _props$theme12.socialIcon.fillHoverColor : (_props$theme13 = props.theme) === null || _props$theme13 === void 0 ? void 0 : _props$theme13.socialIcon.iconHoverColor;
});
export default SocialIconStyles;