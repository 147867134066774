/**
 * @file Manages styles for RichText atom.
 * @author harmeet
 */
import { css } from 'styled-components';
import breakPoints from '../../../themes/breakpoints';
/* stylelint-disable */
var styles = css(["", ";.rich-text-blob &{padding-left:20px;padding-right:20px;li *:last-child{margin-bottom:0;}li > p{margin-bottom:calc(1.45rem / 2);}li > ul{margin-left:1.45rem;}p{margin-left:0;margin-right:0;margin-top:0;margin-bottom:1.45rem;padding-bottom:0;padding-left:0;padding-right:0;padding-top:0;}h2{margin-left:0;margin-right:0;margin-top:0;padding-bottom:0;padding-left:0;padding-right:0;padding-top:0;margin-bottom:1.45rem;}@media (min-width:", "){padding-left:114px;padding-right:114px;}@media (min-width:", "){padding-left:0;padding-right:0;}}"], function (props) {
  return props.inheritedStyles ? props.inheritedStyles : '';
}, breakPoints.mobile, breakPoints.desktop);
/* stylelint-enable */
export default styles;