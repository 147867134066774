import { css } from 'styled-components';
import breakPoints from '../../../themes/breakpoints';
import fontColor from '../../../constants/globalConstants';
import { getBackgroundStyle, isBgColorStyleLight, setNonCtaAnchorStyling, undoSettingOfNonCtaAnchorStyling, replacePrimaryAnchorWithLightPrimaryAnchor } from '../../../utils/utilityFunction';
import textures from '../../../themes/textures';
var backgroundStyles = '';
var HeaderMultiLocationStyles = css(["", ";background-size:cover;width:100%;.location-finder-wrapper{h2,h1,h3,h4,h5,p,ul,ol,.heading_four,.address,.call,.featuredMessage,.fax{color:", ";}.result-item-border{border-color:", ";}a:not(.sg-btn-cpnt){", "}}.multi-location-view-more{display:flex;padding:20px 0;margin:0 auto;justify-content:center;width:100%;a:not(.sg-btn-cpnt){display:inline;width:auto;}}.container{ul{padding-left:0;li{display:block;p{display:list-item;list-style-type:'\u2022  ';list-style-position:inside;}}}ol{padding-left:0;li{display:block;p{display:list-item;list-style-position:inside;}}}}.multi-location-header-acc{text-align:center;margin-bottom:3em;padding-top:3em;&-btn{display:inline-block;margin-top:10px;width:auto;height:auto;}.sg-btn-cpnt.primary-anchor{", "}h2,h1,h3,h4,h5,p,ul,ol,.heading_four{color:", ";}h2,h1,h3,h4,h5{text-align:center;display:block;margin:0 auto 20px;}.heading_four{margin:0 auto 20px;}p{margin:0 auto 20px;}}a:not(.sg-btn-cpnt){display:inline;text-align:left;text-transform:none;height:100%;width:100%;", "}img,p{margin:0;}@media (max-width:", "){width:100%;height:auto;margin:0 auto;}@media (min-width:", "){width:100%;}"], function (props) {
  if (props.multiLocationMenuContainers) {
    var _props$theme, _props$theme2;
    var background = props.multiLocationMenuContainers.background;
    var backgroundImageURLs = background && background.imageBackground ? {
      desktopImage: background.imageBackground && background.imageBackground.url,
      mobileImage: background.imageBackground && background.imageBackground.url
    } : '';
    var overrideId = background ? background.backgroundId : '';
    backgroundStyles = getBackgroundStyle(overrideId, backgroundImageURLs, background && background.colorBackground || ((_props$theme = props.theme) === null || _props$theme === void 0 ? void 0 : _props$theme.promoBanner.bgColor), (_props$theme2 = props.theme) === null || _props$theme2 === void 0 ? void 0 : _props$theme2.palette, textures);
    return backgroundStyles;
  }
}, function (props) {
  var _props$multiLocationM, _props$multiLocationM2, _props$theme3;
  return ((_props$multiLocationM = props.multiLocationMenuContainers) === null || _props$multiLocationM === void 0 ? void 0 : _props$multiLocationM.fontColor) === fontColor.light || ((_props$multiLocationM2 = props.multiLocationMenuContainers) === null || _props$multiLocationM2 === void 0 ? void 0 : _props$multiLocationM2.fontColor) === null && !isBgColorStyleLight(backgroundStyles) ? (_props$theme3 = props.theme) === null || _props$theme3 === void 0 ? void 0 : _props$theme3.promoBanner.textColorLight : '';
}, function (props) {
  return !isBgColorStyleLight(backgroundStyles) ? 'rgba(106,122,98,0.8);' : '';
}, function (props) {
  var _props$multiLocationM3, _props$multiLocationM4, _props$theme4;
  return ((_props$multiLocationM3 = props.multiLocationMenuContainers) === null || _props$multiLocationM3 === void 0 ? void 0 : _props$multiLocationM3.fontColor) === fontColor.light || ((_props$multiLocationM4 = props.multiLocationMenuContainers) === null || _props$multiLocationM4 === void 0 ? void 0 : _props$multiLocationM4.fontColor) === null && !isBgColorStyleLight(backgroundStyles) ? setNonCtaAnchorStyling((_props$theme4 = props.theme) === null || _props$theme4 === void 0 ? void 0 : _props$theme4.promoBanner) : '';
}, function (props) {
  var _props$theme5, _props$theme6;
  return (_props$theme5 = props.theme) !== null && _props$theme5 !== void 0 && _props$theme5.button.hasAlternatePrimaryAnchor && !isBgColorStyleLight(backgroundStyles) ? replacePrimaryAnchorWithLightPrimaryAnchor((_props$theme6 = props.theme) === null || _props$theme6 === void 0 ? void 0 : _props$theme6.button) : "";
}, function (props) {
  var _props$multiLocationM5, _props$multiLocationM6, _props$theme7, _props$theme8;
  return ((_props$multiLocationM5 = props.multiLocationMenuContainers) === null || _props$multiLocationM5 === void 0 ? void 0 : _props$multiLocationM5.fontColor) === fontColor.light || ((_props$multiLocationM6 = props.multiLocationMenuContainers) === null || _props$multiLocationM6 === void 0 ? void 0 : _props$multiLocationM6.fontColor) === null && !isBgColorStyleLight(backgroundStyles) ? (_props$theme7 = props.theme) === null || _props$theme7 === void 0 ? void 0 : _props$theme7.promoBanner.textColorLight : (_props$theme8 = props.theme) === null || _props$theme8 === void 0 ? void 0 : _props$theme8.promoBanner.textColorDark;
}, function (props) {
  var _props$multiLocationM7, _props$multiLocationM8, _props$theme9, _props$theme10;
  return ((_props$multiLocationM7 = props.multiLocationMenuContainers) === null || _props$multiLocationM7 === void 0 ? void 0 : _props$multiLocationM7.fontColor) === fontColor.light || ((_props$multiLocationM8 = props.multiLocationMenuContainers) === null || _props$multiLocationM8 === void 0 ? void 0 : _props$multiLocationM8.fontColor) === null && !isBgColorStyleLight(backgroundStyles) ? setNonCtaAnchorStyling((_props$theme9 = props.theme) === null || _props$theme9 === void 0 ? void 0 : _props$theme9.promoBanner) : undoSettingOfNonCtaAnchorStyling((_props$theme10 = props.theme) === null || _props$theme10 === void 0 ? void 0 : _props$theme10.richText.anchorColor);
}, breakPoints.tabletL, breakPoints.desktop);
export default HeaderMultiLocationStyles;