import { colors } from '../../colors';
var button = {
  allCapsButtonText: true,
  //Hero button
  linkColorHero: colors.equineSnow,
  linkColorHeroHover: colors.equineSnow,
  backgroundColorHero: colors.equineShadow,
  backgroundColorHoverHero: colors.equineIron,
  borderColorHero: colors.equineShadow,
  borderColorHeroHover: colors.equineIron,
  linkLineHeightHero: '24px',
  borderRadiusHero: '8px',
  heroButtonPadding: '20px 35px',
  //primary button
  linkColorPrimary: colors.equineShadow,
  linkColorPrimaryHover: colors.equineSnow,
  backgroundColorPrimary: 'transparent',
  backgroundColorHoverPrimary: colors.equineIron,
  borderColorPrimary: colors.equineShadow,
  borderColorPrimaryHover: colors.equineIron,
  borderRadiusPrimary: '8px',
  //light primary button (these will override primary if bg is dark or fontcolor is supposed to be light)
  hasAlternatePrimaryAnchor: true,
  showWhenFontColorIsNotDark: true,
  linkColorLightPrimary: colors.equineSnow,
  borderColorLightPrimary: colors.equineSnow,
  borderColorLightPrimaryHover: colors.equineSnow,
  backgroundColorHoverLightPrimary: 'rgba(255, 255, 255, 0.2)',
  //Secondary button
  linkColorSecondary: colors.equineSnow,
  linkColorSecondaryHover: colors.equineSnow,
  backgroundColorSecondary: colors.equineShadow,
  backgroundColorHoverSecondary: colors.equineIron,
  borderColorSecondary: colors.equineShadow,
  borderColorSecondaryHover: colors.equineIron,
  borderRadiusSecondary: '8px'
};
export default button;