import { css } from 'styled-components';
var mixins = {
  heading_one: function heading_one(props) {
    return css(["color:", ";font-family:", ";font-size:", ";font-weight:", ";line-height:", ";letter-spacing:", ";"], function (props) {
      return props.theme.fontColorH1;
    }, function (props) {
      return props.theme.fontFamilyH1;
    }, function (props) {
      return props.theme.fontSizeH1;
    }, function (props) {
      return props.theme.fontWeightH1;
    }, function (props) {
      return props.theme.lineHeightH1;
    }, function (props) {
      return props.theme.letterSpacingH1;
    });
  },
  heading_two: function heading_two(props) {
    return css(["color:", ";font-family:", ";font-size:", ";line-height:", ";letter-spacing:", ";font-weight:", ";"], function (props) {
      return props.theme.fontColorH2;
    }, function (props) {
      return props.theme.fontFamilyH2;
    }, function (props) {
      return props.theme.fontSizeH2;
    }, function (props) {
      return props.theme.lineHeightH2;
    }, function (props) {
      return props.theme.letterSpacingH2;
    }, function (props) {
      return props.theme.fontWeightH2;
    });
  },
  heading_three: function heading_three(props) {
    return css(["color:", ";font-family:", ";font-size:", ";line-height:", ";letter-spacing:", ";font-weight:", ";"], function (props) {
      return props.theme.fontColorH3;
    }, function (props) {
      return props.theme.fontFamilyH3;
    }, function (props) {
      return props.theme.fontSizeH3;
    }, function (props) {
      return props.theme.lineHeightH3;
    }, function (props) {
      return props.theme.letterSpacingH3;
    }, function (props) {
      return props.theme.fontWeightH3;
    });
  },
  heading_four: function heading_four(props) {
    return css(["color:", ";font-family:", ";font-size:", ";font-weight:", ";line-height:", ";letter-spacing:", ";"], function (props) {
      return props.theme.fontColorH4;
    }, function (props) {
      return props.theme.fontFamilyH4;
    }, function (props) {
      return props.theme.fontSizeH4;
    }, function (props) {
      return props.theme.fontWeightH4;
    }, function (props) {
      return props.theme.lineHeightH4;
    }, function (props) {
      return props.theme.letterSpacingH4;
    });
  },
  heading_five: function heading_five(props) {
    return css(["color:", ";font-family:", ";font-size:", ";font-weight:", ";line-height:", ";letter-spacing:", ";"], function (props) {
      return props.theme.fontColorH5;
    }, function (props) {
      return props.theme.fontFamilyH5;
    }, function (props) {
      return props.theme.fontSizeH5;
    }, function (props) {
      return props.theme.fontWeightH5;
    }, function (props) {
      return props.theme.lineHeightH5;
    }, function (props) {
      return props.theme.letterSpacingH5;
    });
  },
  heading_one_desktop: function heading_one_desktop(props) {
    return css(["font-size:", ";line-height:", ";letter-spacing:", ";"], function (props) {
      return props.theme.desktop.fontSizeH1;
    }, function (props) {
      return props.theme.desktop.lineHeightH1;
    }, function (props) {
      return props.theme.desktop.letterSpacingH1;
    });
  },
  heading_two_desktop: function heading_two_desktop(props) {
    return css(["font-size:", ";line-height:", ";letter-spacing:", ";"], function (props) {
      return props.theme.desktop.fontSizeH2;
    }, function (props) {
      return props.theme.desktop.lineHeightH2;
    }, function (props) {
      return props.theme.desktop.letterSpacingH2;
    });
  },
  heading_three_desktop: function heading_three_desktop(props) {
    return css(["font-size:", ";line-height:", ";letter-spacing:", ";"], function (props) {
      return props.theme.desktop.fontSizeH3;
    }, function (props) {
      return props.theme.desktop.lineHeightH3;
    }, function (props) {
      return props.theme.desktop.letterSpacingH3;
    });
  },
  heading_four_desktop: function heading_four_desktop(props) {
    return css(["font-size:", ";line-height:", ";letter-spacing:", ";"], function (props) {
      return props.theme.desktop.fontSizeH4;
    }, function (props) {
      return props.theme.desktop.lineHeightH4;
    }, function (props) {
      return props.theme.desktop.letterSpacingH4;
    });
  },
  heading_five_desktop: function heading_five_desktop(props) {
    return css(["font-size:", ";line-height:", ";letter-spacing:", ";"], function (props) {
      return props.theme.desktop.fontSizeH5;
    }, function (props) {
      return props.theme.desktop.lineHeightH5;
    }, function (props) {
      return props.theme.desktop.letterSpacingH5;
    });
  }
};
export default mixins;