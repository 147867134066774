import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["locationName", "className", "ariaLabel", "socialUrl", "socialIconType", "dataAnalyticsType", "dataAnalyticsValue", "dataAnalyticsVariation"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import styles from './SocialIcon.style';
import Anchor from '../../atoms/Anchor';
import FacebookIcon from '../../../static/images/icons/Facebook.js';
import TwitterIcon from '../../../static/images/icons/Twitter.js';
import InstagramIcon from '../../../static/images/icons/Instagram.js';
import LinkedInIcon from '../../../static/images/icons/Linkedin.js';
import YouTubeIcon from '../../../static/images/icons/Youtube.js';
import { linkTargetWindow } from '../../../constants/globalConstants';
var SocialIcon = function SocialIcon(_ref) {
  var locationName = _ref.locationName,
    className = _ref.className,
    ariaLabel = _ref.ariaLabel,
    socialUrl = _ref.socialUrl,
    socialIconType = _ref.socialIconType,
    dataAnalyticsType = _ref.dataAnalyticsType,
    dataAnalyticsValue = _ref.dataAnalyticsValue,
    dataAnalyticsVariation = _ref.dataAnalyticsVariation,
    others = _objectWithoutProperties(_ref, _excluded);
  var profileLabel = dataAnalyticsValue + ' profile for ' + locationName;
  return __jsx("section", _extends({}, others, {
    className: className
  }), __jsx(Anchor, {
    title: socialIconType,
    to: socialUrl,
    "data-analytics-type": dataAnalyticsType,
    "data-analytics-value": dataAnalyticsValue,
    "data-analytics-variation": dataAnalyticsVariation,
    ctaBehavior: linkTargetWindow.newWindow,
    "aria-label": profileLabel
  }, socialIconType === 'Facebook' && __jsx(FacebookIcon, null), socialIconType === 'Twitter' && __jsx(TwitterIcon, null), socialIconType === 'Instagram' && __jsx(InstagramIcon, null), socialIconType === 'Linked In' && __jsx(LinkedInIcon, null), socialIconType === 'Youtube' && __jsx(YouTubeIcon, null)));
};
SocialIcon.defaultProps = {
  className: '',
  ariaLabel: '',
  socialUrl: '',
  socialIconType: ''
};
export default styled(SocialIcon).withConfig({
  componentId: "sc-ht50ix-0"
})(["", ";"], styles);