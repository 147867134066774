import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["children", "className", "inheritedStyles", "styleType"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import styles from './Para.style';
var Para = function Para(_ref) {
  var children = _ref.children,
    className = _ref.className,
    inheritedStyles = _ref.inheritedStyles,
    styleType = _ref.styleType,
    others = _objectWithoutProperties(_ref, _excluded);
  return __jsx("p", _extends({
    className: "".concat(styleType, " ").concat(className)
  }, others), children);
};
Para.defaultProps = {
  inheritedStyles: ''
};
export default styled(Para).withConfig({
  componentId: "sc-ovnq1p-0"
})(["", ";"], styles);
export { Para as ParaVanilla };