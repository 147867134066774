import _objectWithoutProperties from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["className", "appointmentCta", "displayAppointmentLabel", "pharmacyCta", "isEmergencyBtn", "address", "sms", "phone", "isBurgerOpen", "variation", "mobileCTAs", "googleMyBusiness"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import styles from './MobileCTAs.style';
import Anchor from '../../../atoms/Anchor';
import CalendarIcon from '../../../../static/images/icons/Calendar';
import LocationIcon from '../../../../static/images/icons/Location';
import PharmacyIcon from '../../../../static/images/icons/Cart';
import PhoneIcon from '../../../../static/images/icons/Phone';
import SmsIcon from '../../../../static/images/icons/Comment';
import { linkTargetWindow } from '../../../../constants/globalConstants';
import { getAddressURL,
// formatPhoneNumberHyphen,
getCompleteAddress, convertPhoneFormat } from '../../../../utils/utilityFunction';
import { locationLabels } from '../../../../constants/labels/en';
import analyticsConstants from '../../../../constants/analyticsConstants';
var MobileCTAs = function MobileCTAs(_ref) {
  var className = _ref.className,
    appointmentCta = _ref.appointmentCta,
    displayAppointmentLabel = _ref.displayAppointmentLabel,
    pharmacyCta = _ref.pharmacyCta,
    isEmergencyBtn = _ref.isEmergencyBtn,
    address = _ref.address,
    sms = _ref.sms,
    phone = _ref.phone,
    isBurgerOpen = _ref.isBurgerOpen,
    variation = _ref.variation,
    mobileCTAs = _ref.mobileCTAs,
    googleMyBusiness = _ref.googleMyBusiness,
    others = _objectWithoutProperties(_ref, _excluded);
  var CTAOff = mobileCTAs && mobileCTAs.includes('Off') ? true : false;
  var addressString = address && getCompleteAddress(address.line1, address.line2, address.city, address.region, address.postalCode);
  var GMBUrl = googleMyBusiness && "https://maps.google.com/maps?cid=".concat(googleMyBusiness);
  var addressStringURL = getAddressURL(addressString);
  var headerAnalytics = analyticsConstants.globalHeader;
  var splitCtas = pharmacyCta && pharmacyCta.url && (mobileCTAs && mobileCTAs.length >= 3 || mobileCTAs == null);
  return CTAOff ? '' : __jsx("div", {
    className: "".concat(className, " ").concat(isBurgerOpen ? 'burger-open' : 'burger-close')
  }, splitCtas && __jsx("div", {
    className: "splitCtas"
  }, (mobileCTAs == null || mobileCTAs && mobileCTAs.includes('Book')) && appointmentCta && __jsx(React.Fragment, null, __jsx("div", {
    className: "book"
  }, __jsx(Anchor, {
    title: displayAppointmentLabel ? appointmentCta.label : locationLabels.bookGrid,
    to: appointmentCta.url,
    ctaBehavior: appointmentCta.behavior,
    hyperlinkType: appointmentCta.hyperlinkType,
    "data-analytics-type": headerAnalytics.analyticsType.GLOBAL_NAV,
    "data-analytics-value": appointmentCta.dataAnalyticsValue ? appointmentCta.dataAnalyticsValue : headerAnalytics.analyticsValue.GLOBAL_NAV_APPOINTMENT,
    "data-analytics-variation": variation,
    styleType: "secondary-link"
  }, displayAppointmentLabel ? null : __jsx(CalendarIcon, null), __jsx("span", {
    className: "link-CTA"
  }, displayAppointmentLabel ? appointmentCta.label : locationLabels.bookGrid)), __jsx("span", {
    className: "details-CTA"
  }, __jsx(Anchor, {
    title: appointmentCta.label,
    to: appointmentCta.url,
    ctaBehavior: appointmentCta.behavior,
    hyperlinkType: appointmentCta.hyperlinkType,
    "data-analytics-type": headerAnalytics.analyticsType.GLOBAL_NAV,
    "data-analytics-value": appointmentCta.dataAnalyticsValue ? appointmentCta.dataAnalyticsValue : headerAnalytics.analyticsValue.GLOBAL_NAV_APPOINTMENT,
    "data-analytics-variation": variation,
    styleType: "primary-anchor",
    className: " nav-btn"
  }, appointmentCta.label))))), __jsx("div", {
    className: "nonSplit"
  }, (mobileCTAs == null || mobileCTAs && mobileCTAs.includes('Map')) && __jsx("div", {
    className: "map"
  }, __jsx(Anchor, {
    title: addressString,
    ctaBehavior: linkTargetWindow.newWindow,
    to: GMBUrl || addressStringURL,
    "data-analytics-type": headerAnalytics.analyticsType.GLOBAL_NAV,
    "data-analytics-value": headerAnalytics.analyticsValue.GLOBAL_NAV_ADDRESS,
    "data-analytics-variation": variation,
    styleType: "secondary-link"
  }, __jsx(LocationIcon, null), __jsx("span", {
    className: "link-CTA"
  }, locationLabels.map), __jsx("span", {
    className: "details-CTA"
  }, addressString))), (mobileCTAs == null || mobileCTAs && mobileCTAs.includes('Call')) && __jsx(React.Fragment, null, __jsx("div", {
    className: "call"
  }, __jsx(Anchor, {
    title: "".concat(locationLabels.call, " ").concat(convertPhoneFormat(phone)),
    href: "tel:".concat(convertPhoneFormat(phone)),
    to: "tel:".concat(convertPhoneFormat(phone)),
    "data-analytics-type": headerAnalytics.analyticsType.GLOBAL_NAV,
    "data-analytics-value": headerAnalytics.analyticsValue.GLOBAL_NAV_PHONE,
    "data-analytics-variation": variation,
    styleType: "secondary-link"
  }, __jsx(PhoneIcon, null), __jsx("span", {
    className: "link-CTA"
  }, locationLabels.call), __jsx("span", {
    className: "details-CTA"
  }, convertPhoneFormat(phone))))), (mobileCTAs == null || mobileCTAs && mobileCTAs.includes('Text')) && sms && __jsx(React.Fragment, null, __jsx("div", {
    className: "sms"
  }, __jsx(Anchor, {
    title: "Text ".concat(convertPhoneFormat(sms)),
    href: "sms:".concat(convertPhoneFormat(sms)),
    to: "sms:".concat(sms),
    "data-analytics-type": headerAnalytics.analyticsType.GLOBAL_NAV,
    "data-analytics-value": headerAnalytics.analyticsValue.GLOBAL_NAV_PHONE,
    "data-analytics-variation": variation,
    styleType: "secondary-link"
  }, __jsx(SmsIcon, null), __jsx("span", {
    className: "link-CTA"
  }, "Text"), __jsx("span", {
    className: "details-CTA"
  }, convertPhoneFormat(sms)), __jsx("div", null)))), isEmergencyBtn ? __jsx(React.Fragment, null, __jsx("div", {
    className: "pharmacyCta"
  }, __jsx(Anchor, {
    title: pharmacyCta.label,
    to: pharmacyCta.url,
    ctaBehavior: pharmacyCta.behavior,
    hyperlinkType: pharmacyCta.hyperlinkType,
    "data-analytics-type": headerAnalytics.analyticsType.GLOBAL_NAV,
    "data-analytics-value": pharmacyCta.dataAnalyticsValue ? pharmacyCta.dataAnalyticsValue : headerAnalytics.analyticsValue.GLOBAL_NAV_APPOINTMENT,
    "data-analytics-variation": variation,
    styleType: "secondary-link"
  }, __jsx("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "25",
    height: "25",
    viewBox: "0 0 25 25"
  }, __jsx("g", {
    transform: "translate(-50.938 -147.563)"
  }, __jsx("path", {
    d: "M70.382,165.063v6.945H63.438v10.111h6.945v6.944H80.493v-6.944h6.945V172.008H80.493v-6.945Z",
    transform: "translate(-12 -17)"
  }))), __jsx("span", {
    className: "link-CTA"
  }, pharmacyCta.label)), __jsx("span", {
    className: "details-CTA"
  }, __jsx(Anchor, {
    title: pharmacyCta.label,
    to: pharmacyCta.url,
    ctaBehavior: pharmacyCta.behavior,
    hyperlinkType: pharmacyCta.hyperlinkType,
    "data-analytics-type": headerAnalytics.analyticsType.GLOBAL_NAV,
    "data-analytics-value": pharmacyCta.dataAnalyticsValue ? pharmacyCta.dataAnalyticsValue : headerAnalytics.analyticsValue.GLOBAL_NAV_APPOINTMENT,
    "data-analytics-variation": variation,
    styleType: "primary-anchor",
    className: " nav-btn emergencyBtn"
  }, __jsx("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "25",
    height: "25",
    viewBox: "0 0 25 25"
  }, __jsx("g", {
    transform: "translate(-50.938 -147.563)"
  }, __jsx("path", {
    d: "M70.382,165.063v6.945H63.438v10.111h6.945v6.944H80.493v-6.944h6.945V172.008H80.493v-6.945Z",
    transform: "translate(-12 -17)"
  }))), pharmacyCta.label)))) : pharmacyCta && __jsx(React.Fragment, null, __jsx("div", {
    className: "pharmacyCta"
  }, __jsx(Anchor, {
    title: pharmacyCta.label,
    to: pharmacyCta.url,
    ctaBehavior: pharmacyCta.behavior,
    hyperlinkType: pharmacyCta.hyperlinkType,
    "data-analytics-type": headerAnalytics.analyticsType.GLOBAL_NAV,
    "data-analytics-value": pharmacyCta.dataAnalyticsValue ? pharmacyCta.dataAnalyticsValue : headerAnalytics.analyticsValue.GLOBAL_NAV_APPOINTMENT,
    "data-analytics-variation": variation,
    styleType: "secondary-link"
  }, __jsx(PharmacyIcon, null), __jsx("span", {
    className: "link-CTA"
  }, pharmacyCta.label)), __jsx("span", {
    className: "details-CTA"
  }, __jsx(Anchor, {
    title: pharmacyCta.label,
    to: pharmacyCta.url,
    ctaBehavior: pharmacyCta.behavior,
    hyperlinkType: pharmacyCta.hyperlinkType,
    "data-analytics-type": headerAnalytics.analyticsType.GLOBAL_NAV,
    "data-analytics-value": pharmacyCta.dataAnalyticsValue ? pharmacyCta.dataAnalyticsValue : headerAnalytics.analyticsValue.GLOBAL_NAV_APPOINTMENT,
    "data-analytics-variation": variation,
    styleType: "primary-anchor",
    className: " nav-btn"
  }, pharmacyCta.label)))), !splitCtas && (mobileCTAs == null || mobileCTAs && mobileCTAs.includes('Book')) && appointmentCta && __jsx(React.Fragment, null, __jsx("div", {
    className: "book"
  }, __jsx(Anchor, {
    title: displayAppointmentLabel ? appointmentCta.label : locationLabels.bookGrid,
    to: appointmentCta.url,
    ctaBehavior: appointmentCta.behavior,
    hyperlinkType: appointmentCta.hyperlinkType,
    "data-analytics-type": headerAnalytics.analyticsType.GLOBAL_NAV,
    "data-analytics-value": appointmentCta.dataAnalyticsValue ? appointmentCta.dataAnalyticsValue : headerAnalytics.analyticsValue.GLOBAL_NAV_APPOINTMENT,
    "data-analytics-variation": variation,
    styleType: "secondary-link"
  }, displayAppointmentLabel ? null : __jsx(CalendarIcon, null), __jsx("span", {
    className: "link-CTA"
  }, displayAppointmentLabel ? appointmentCta.label : locationLabels.bookGrid)), __jsx("span", {
    className: "details-CTA"
  }, __jsx(Anchor, {
    title: appointmentCta.label,
    to: appointmentCta.url,
    ctaBehavior: appointmentCta.behavior,
    hyperlinkType: appointmentCta.hyperlinkType,
    "data-analytics-type": headerAnalytics.analyticsType.GLOBAL_NAV,
    "data-analytics-value": appointmentCta.dataAnalyticsValue ? appointmentCta.dataAnalyticsValue : headerAnalytics.analyticsValue.GLOBAL_NAV_APPOINTMENT,
    "data-analytics-variation": variation,
    styleType: "primary-anchor",
    className: " nav-btn"
  }, appointmentCta.label))))));
};
MobileCTAs.defaultProps = {
  className: ''
};
export default styled(MobileCTAs).withConfig({
  componentId: "sc-163y7xo-0"
})(["", ";"], styles);