import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["children", "className", "index", "isSelected", "tabId", "inheritedStyles"];
var __jsx = React.createElement;
/**
 *
 * TabPanel
 *
 */
import React from 'react';
import styled from 'styled-components';
import styles from './TabPanel.style';
var TabPanel = function TabPanel(_ref) {
  var children = _ref.children,
    className = _ref.className,
    index = _ref.index,
    isSelected = _ref.isSelected,
    tabId = _ref.tabId,
    inheritedStyles = _ref.inheritedStyles,
    others = _objectWithoutProperties(_ref, _excluded);
  return __jsx("div", _extends({
    id: tabId,
    className: className,
    role: "tabpanel",
    "aria-expanded": isSelected,
    "aria-hidden": !isSelected,
    "aria-labelledby": "tab_".concat(index),
    hidden: !isSelected
  }, others), children);
};
TabPanel.defaultProps = {
  className: '',
  inheritedStyles: ''
};
export default styled(TabPanel).withConfig({
  componentId: "sc-yr2uqf-0"
})(["", ";"], styles);
export { TabPanel as TabPanelVanilla };