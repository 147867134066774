import { css } from 'styled-components';
import breakPoints from '../../../themes/breakpoints';
import fontColor from '../../../constants/globalConstants';
import textures from '../../../themes/textures';
import { getBackgroundStyle, isBgColorStyleLight } from '../../../utils/utilityFunction';
var backgroundStyles = '';
var styles = css(["", ";width:100%;max-width:1600px;padding:10px 0;margin:0 auto;text-align:center;.container{& > *{padding:0;margin:0;}ul,ol{li{display:block;p{display:list-item;list-style-position:inside;}}}h2,h3,h4,ul,ol,a,p,.heading_four{color:", ";}a{text-decoration:underline;}}@media (min-width:", "){padding:12px 0;}"], function (props) {
  if (props.bannerData) {
    var _props$bannerData = props.bannerData,
      backgroundImage = _props$bannerData.backgroundImage,
      overrideBackground = _props$bannerData.overrideBackground;
    var backgroundImageURLs = backgroundImage ? {
      desktopImage: backgroundImage.image && backgroundImage.image.url,
      mobileImage: backgroundImage.mobileImage && backgroundImage.mobileImage.url
    } : overrideBackground && overrideBackground.imageBackground ? {
      desktopImage: overrideBackground.imageBackground && overrideBackground.imageBackground.url,
      mobileImage: overrideBackground.imageBackground && overrideBackground.imageBackground.url
    } : '';
    var overrideId = overrideBackground ? overrideBackground.backgroundId : '';
    backgroundStyles = getBackgroundStyle(overrideId, backgroundImageURLs, overrideBackground && overrideBackground.colorBackground || props.theme.pencilBanner.bgColor, props.theme.palette, textures);
    return backgroundStyles;
  }
}, function (props) {
  var _props$bannerData2, _props$bannerData3, _props$bannerData4;
  if ((props === null || props === void 0 ? void 0 : (_props$bannerData2 = props.bannerData) === null || _props$bannerData2 === void 0 ? void 0 : _props$bannerData2.fontColor) === null) {
    return isBgColorStyleLight(backgroundStyles) ? props.theme.pencilBanner.textColorDark : props.theme.pencilBanner.textColorLight;
  }
  if ((props === null || props === void 0 ? void 0 : (_props$bannerData3 = props.bannerData) === null || _props$bannerData3 === void 0 ? void 0 : _props$bannerData3.fontColor) === fontColor.light) {
    return props.theme.pencilBanner.textColorLight;
  } else if ((props === null || props === void 0 ? void 0 : (_props$bannerData4 = props.bannerData) === null || _props$bannerData4 === void 0 ? void 0 : _props$bannerData4.fontColor) === fontColor.dark) {
    return props.theme.pencilBanner.textColorDark;
  }
}, breakPoints.desktop);
export default styles;