import { css } from 'styled-components';
import breakPoints from '../../../../themes/breakpoints';
var styles = css(["padding-right:30px;.checkbox-wrapper{position:relative;margin:0;padding:0;white-space:nowrap;& input{opacity:0;vertical-align:middle;position:relative;width:20px;height:20px;}& label{cursor:pointer;display:inline-block;vertical-align:middle;padding-left:15px;&:before{position:absolute;left:0;top:calc((100% - 20px) / 2);content:'';height:20px;width:20px;border:solid 2px ", ";background-color:", ";}&:after{position:absolute;left:5px;top:calc((100% - 5px - 4px) / 2);content:'';display:inline-block;height:5px;width:10px;border-left:2px solid;border-bottom:2px solid;transform:rotate(-45deg);}}& input + label:after{content:none;}& input:focus + label{outline:1px solid transparent;}& input:checked + label:after{content:'';color:", ";background-color:", ";}& input:checked + label:before{background-color:", ";border:none;}}.error{color:", ";input{border-color:", ";}}@media (max-width:", "){padding-bottom:30px;padding-right:20px;}"], function (props) {
  var _props$theme;
  return (_props$theme = props.theme) === null || _props$theme === void 0 ? void 0 : _props$theme.form.labelColor;
}, function (props) {
  var _props$theme2;
  return (_props$theme2 = props.theme) === null || _props$theme2 === void 0 ? void 0 : _props$theme2.form.bgColor;
}, function (props) {
  var _props$theme3;
  return (_props$theme3 = props.theme) === null || _props$theme3 === void 0 ? void 0 : _props$theme3.form.bgColor;
}, function (props) {
  var _props$theme4;
  return (_props$theme4 = props.theme) === null || _props$theme4 === void 0 ? void 0 : _props$theme4.form.labelColor;
}, function (props) {
  var _props$theme5;
  return (_props$theme5 = props.theme) === null || _props$theme5 === void 0 ? void 0 : _props$theme5.form.labelColor;
}, function (props) {
  var _props$theme6;
  return (_props$theme6 = props.theme) === null || _props$theme6 === void 0 ? void 0 : _props$theme6.form.errorColor;
}, function (props) {
  var _props$theme7;
  return (_props$theme7 = props.theme) === null || _props$theme7 === void 0 ? void 0 : _props$theme7.form.errorColor;
}, breakPoints.tabletP);
export default styles;