import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["children", "className", "inheritedStyles", "addressLine1", "addressLine2", "city", "state", "postalCode"];
var __jsx = React.createElement;
import React, { Fragment } from 'react';
import styled from 'styled-components';
import styles from './Address.style';
var Address = function Address(_ref) {
  var children = _ref.children,
    className = _ref.className,
    inheritedStyles = _ref.inheritedStyles,
    addressLine1 = _ref.addressLine1,
    addressLine2 = _ref.addressLine2,
    city = _ref.city,
    state = _ref.state,
    postalCode = _ref.postalCode,
    others = _objectWithoutProperties(_ref, _excluded);
  return __jsx("address", _extends({
    className: className
  }, others), __jsx(Fragment, null, addressLine1 && __jsx("span", {
    className: "addressLine1"
  }, addressLine1), addressLine2 && addressLine2 !== 'undefined' && __jsx("span", {
    className: "addressLine2"
  }, addressLine2), city && __jsx("span", {
    className: "city"
  }, city), state && __jsx(Fragment, null, ', ', __jsx("span", {
    className: "state"
  }, state)), postalCode && __jsx("span", {
    className: "postalCode"
  }, postalCode)));
};
Address.defaultProps = {
  inheritedStyles: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  postalCode: ''
};
export default styled(Address).withConfig({
  componentId: "sc-1xxkgde-0"
})(["", ";"], styles);
export { Address as AddressVanilla };