import _toConsumableArray from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _objectWithoutProperties from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["className", "multiLocationMenuContainers", "isBlade", "themeName"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import styles from './HeaderMultiLocation.style';
import MultiLocationGrid from '../../molecules/MultiLocationGrid';
import LocationFinderForm from '../../organisms/LocationFinderForm';
import Anchor from '../../atoms/Anchor';
import RichText from '../../atoms/RichText/RichText';
import Heading from '../../atoms/Heading/Heading';
import Para from '../../atoms/Para/Para';
import urlSlug from 'url-slug';
import { useYextFetch } from '../../../hooks/useYextFetch';
import dynamic from 'next/dynamic';
var HeaderMultiLocationBlock = styled.section.withConfig({
  componentId: "sc-1ypjqlm-0"
})(["", ";"], styles);
var HeaderMultiLocation = function HeaderMultiLocation(_ref) {
  var className = _ref.className,
    multiLocationMenuContainers = _ref.multiLocationMenuContainers,
    isBlade = _ref.isBlade,
    themeName = _ref.themeName,
    others = _objectWithoutProperties(_ref, _excluded);
  // Testing Multi Location Grid
  var bladeVariation = multiLocationMenuContainers && multiLocationMenuContainers.variation;
  var anchorLink = multiLocationMenuContainers && (multiLocationMenuContainers === null || multiLocationMenuContainers === void 0 ? void 0 : multiLocationMenuContainers.anchorLink) || '';
  if (bladeVariation == 'Grid View') {
    return __jsx(HeaderMultiLocationBlock, {
      id: anchorLink
    }, __jsx(MultiLocationGrid, {
      isBlade: isBlade,
      multiLocationMenuContainers: multiLocationMenuContainers
    }));
  } else if (bladeVariation == 'Accordion List by Region') {
    return __jsx(HeaderMultiLocationBlock, {
      multiLocationMenuContainers: multiLocationMenuContainers,
      id: anchorLink
    }, __jsx("div", {
      style: {
        textAlign: 'center'
      },
      className: "col-md-10 col-md-offset-1 container multi-location-header-acc"
    }, multiLocationMenuContainers && multiLocationMenuContainers.headline && __jsx(Heading, {
      HeadingType: "h2",
      id: "".concat(urlSlug(multiLocationMenuContainers && multiLocationMenuContainers.headline))
    }, multiLocationMenuContainers && multiLocationMenuContainers.headline), multiLocationMenuContainers && multiLocationMenuContainers.subHeadline && __jsx(Para, {
      className: "heading_four"
    }, multiLocationMenuContainers && multiLocationMenuContainers.subHeadline), multiLocationMenuContainers.bodyText && multiLocationMenuContainers.bodyText.json && __jsx("div", {
      className: "col-md-10 col-md-offset-1"
    }, ' ', __jsx(RichText, {
      jsonContent: multiLocationMenuContainers.bodyText.json
    }), ' '), multiLocationMenuContainers && multiLocationMenuContainers.cta && __jsx(Anchor, {
      title: multiLocationMenuContainers.cta.label,
      to: multiLocationMenuContainers.cta.url,
      hyperlinkType: multiLocationMenuContainers.cta.hyperlinkType,
      ctaBehavior: multiLocationMenuContainers.cta.behavior,
      "aria-label": multiLocationMenuContainers.cta.label,
      styleType: "primary-anchor",
      className: " multi-location-header-acc-btn"
    }, multiLocationMenuContainers.cta.label)), __jsx(LocationFinderForm, {
      multiLocationMenuContainers: multiLocationMenuContainers
    }), !isBlade && multiLocationMenuContainers.bladeCta && __jsx("div", {
      className: "multi-location-view-more"
    }, __jsx(Anchor, {
      title: multiLocationMenuContainers.bladeCta && multiLocationMenuContainers.bladeCta.label || 'View More',
      to: multiLocationMenuContainers.bladeCta && multiLocationMenuContainers.bladeCta.url,
      hyperlinkType: multiLocationMenuContainers.bladeCta.hyperlinkType,
      styleType: "primary-anchor",
      className: "nav-btn center",
      ctaBehavior: multiLocationMenuContainers.bladeCta && multiLocationMenuContainers.bladeCta.behavior,
      "data-analytics-type": 'Multi-location grid blade CTA',
      "data-analytics-variation": multiLocationMenuContainers.variation
    }, multiLocationMenuContainers.bladeCta && multiLocationMenuContainers.bladeCta.label || 'View More')));
  } else if (bladeVariation == 'Interactive Map Right' || bladeVariation == 'Interactive Map Left') {
    var _multiLocationMenuCon, _multiLocationMenuCon2;
    var locations = [];
    var yextSingleIds = multiLocationMenuContainers === null || multiLocationMenuContainers === void 0 ? void 0 : (_multiLocationMenuCon = multiLocationMenuContainers.locationContainer) === null || _multiLocationMenuCon === void 0 ? void 0 : _multiLocationMenuCon.yextIds;
    var yextFolderIds = multiLocationMenuContainers === null || multiLocationMenuContainers === void 0 ? void 0 : (_multiLocationMenuCon2 = multiLocationMenuContainers.locationContainer) === null || _multiLocationMenuCon2 === void 0 ? void 0 : _multiLocationMenuCon2.yextFolderIds;
    if (yextSingleIds) {
      var yextLocationsInformationData = useYextFetch(yextSingleIds, false);
      if (yextLocationsInformationData !== null && yextLocationsInformationData !== void 0 && yextLocationsInformationData.entities) locations.push.apply(locations, _toConsumableArray(yextLocationsInformationData.entities));
    }
    if (yextFolderIds) {
      var yextFolderInformationData = useYextFetch(yextFolderIds, true);
      if (yextFolderInformationData !== null && yextFolderInformationData !== void 0 && yextFolderInformationData.entities) locations.push.apply(locations, _toConsumableArray(yextFolderInformationData.entities));
    }

    // import GoogleMapsMultiLocations from '../GoogleMapsMultiLocation/GoogleMapsMultiLocations';
    var GoogleMapsMultiLocations = dynamic(function () {
      return import('../GoogleMapsMultiLocation/GoogleMapsMultiLocations');
    }, {
      ssr: false,
      loadableGenerated: {
        webpack: function webpack() {
          return [require.resolveWeak('../GoogleMapsMultiLocation/GoogleMapsMultiLocations')];
        }
      }
    });
    return __jsx(HeaderMultiLocationBlock, {
      id: anchorLink
    }, __jsx(GoogleMapsMultiLocations, {
      locations: locations,
      themeName: themeName,
      locationInformation: multiLocationMenuContainers.locationInformation,
      variation: multiLocationMenuContainers.variation,
      mapFilterSearch: multiLocationMenuContainers.mapFilterSearch,
      cardsCta: multiLocationMenuContainers.cardsCta
    }));
  } else {
    return 'No Variation Selected';
  }
};
HeaderMultiLocation.defaultProps = {
  className: ''
};
export default HeaderMultiLocation;