import _objectWithoutProperties from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["contactUsNvaObject", "locationName", "addressObject", "hoursOfOperationObject", "logoImage", "mapImage", "primaryLinksObject", "utilityLinksObject", "socialIconsData", "footerMessage", "footerHeadline", "footerSubHeadline", "dataAnalyticsLogo", "dataAnalyticsAddress", "dataAnalyticsPrimaryLinks", "dataAnalyticsSecondaryLinks", "dataAnalyticsSocialLinks", "isfooterWithoutForm"];
var __jsx = React.createElement;
/**
 * @author Harmeet
 */import React, { useState } from 'react';
import styled from 'styled-components';
import styles from './FooterNva.style';
import TextCard from '../TextCard';
import ContactUsNva from '../ContactUsNva';
import Image from '../../atoms/Image';
import SocialIcons from '../SocialIcons';
import List from '../List';
import ListItem from '../List/ListItem';
import { getYear } from '../../../utils/utilityFunction';
import { isRenderForm } from '../../../utils/formUtility';
import Anchor from '../../atoms/Anchor';
import { FooterCopyright } from '../../../constants/labels/en';
import analyticsConstants from '../../../constants/analyticsConstants';
import { srcSetSettingImg } from './FooterNvaConfig';
var FooterSection = styled.section.withConfig({
  componentId: "sc-1j7wvlx-0"
})(["", ";"], styles);
var FooterNva = function FooterNva(_ref) {
  var contactUsNvaObject = _ref.contactUsNvaObject,
    locationName = _ref.locationName,
    addressObject = _ref.addressObject,
    hoursOfOperationObject = _ref.hoursOfOperationObject,
    logoImage = _ref.logoImage,
    mapImage = _ref.mapImage,
    primaryLinksObject = _ref.primaryLinksObject,
    utilityLinksObject = _ref.utilityLinksObject,
    socialIconsData = _ref.socialIconsData,
    footerMessage = _ref.footerMessage,
    footerHeadline = _ref.footerHeadline,
    footerSubHeadline = _ref.footerSubHeadline,
    dataAnalyticsLogo = _ref.dataAnalyticsLogo,
    dataAnalyticsAddress = _ref.dataAnalyticsAddress,
    dataAnalyticsPrimaryLinks = _ref.dataAnalyticsPrimaryLinks,
    dataAnalyticsSecondaryLinks = _ref.dataAnalyticsSecondaryLinks,
    dataAnalyticsSocialLinks = _ref.dataAnalyticsSocialLinks,
    isfooterWithoutForm = _ref.isfooterWithoutForm,
    others = _objectWithoutProperties(_ref, _excluded);
  // // TODO: change the way that we are switching the layout on 'Contact us' page
  // if (
  //   window &&
  //   window.location &&
  //   window.location.href.includes('/contact-us')
  // ) {
  //   isfooterWithoutForm = true;
  // }
  var variation = isfooterWithoutForm ? 'variation-b' : 'variation-a';
  var contactUsLeftColMd = isfooterWithoutForm ? "col-md-5" : "col-md-6";
  var contactUsRightColMd = isfooterWithoutForm ? "col-md-7" : "col-md-6";
  var headerAnalytics = analyticsConstants.globalHeader;
  var _useState = useState(getYear()),
    year = _useState[0];
  return __jsx(FooterSection, {
    className: "".concat(variation, " footer molecule row")
  }, !isfooterWithoutForm && __jsx("div", {
    className: "textCard col-xs-12 col-md-3"
  }, footerHeadline && __jsx(TextCard, {
    className: "service-text",
    headingText: footerHeadline,
    headingType: "h2",
    subHeadingText: footerSubHeadline,
    headingTypeSubHeading: "h3",
    descriptionType: 'RichText',
    descriptionText: footerMessage
  }), socialIconsData && __jsx(SocialIcons, {
    locationName: locationName,
    dataAnalyticsSocialLinks: dataAnalyticsSocialLinks,
    socialIconsData: socialIconsData
  })), !isfooterWithoutForm && isRenderForm(contactUsNvaObject) && __jsx("div", {
    className: "contactUsForm col-xs-12 col-md-9"
  }, __jsx(ContactUsNva, {
    contactUsData: contactUsNvaObject,
    showHeading: false
  })), !isfooterWithoutForm && __jsx("div", {
    className: "line-separator col-xs-12"
  }), logoImage && __jsx("div", {
    className: 'businessLogo col-xs-12 col-sm-5 ' + contactUsLeftColMd,
    itemScope: true,
    itemType: "http://schema.org/ImageObject"
  }, __jsx("div", {
    className: "imageWrapper"
  }, __jsx(Anchor, {
    title: "Logo",
    to: "/",
    "data-analytics-type": headerAnalytics.analyticsType.GLOBAL_NAV,
    "data-analytics-value": headerAnalytics.analyticsValue.GLOBAL_NAV_LOGO,
    "data-analytics-variation": variation
  }, __jsx(Image, {
    "data-analytics-type": dataAnalyticsLogo && dataAnalyticsLogo.type ? dataAnalyticsLogo.type : '',
    "data-analytics-variation": dataAnalyticsLogo && dataAnalyticsLogo.variation ? dataAnalyticsLogo.variation : '',
    alt: logoImage.alt || 'Logo',
    title: logoImage.title || 'Logo',
    contentType: logoImage.contentType,
    src: logoImage.src,
    imageOptimisationType: (logoImage === null || logoImage === void 0 ? void 0 : logoImage.contentType) && logoImage.contentType.includes('png') && 'logo',
    srcSetSettingImg: srcSetSettingImg
  }), __jsx("link", {
    href: logoImage.src
  }))), __jsx("div", {
    className: "heading_five heading-5 no-bar"
  }, FooterCopyright.copyrightSymbol, " ", FooterCopyright.copyright, " ", year)), __jsx("div", {
    className: 'copyright col-xs-12 col-sm-7 end-md ' + contactUsRightColMd
  }, __jsx("div", {
    className: "row container"
  }, utilityLinksObject && __jsx(List, {
    ordered: false,
    className: "utilityLinks row"
  }, utilityLinksObject.map(function (listItem, index) {
    return __jsx(ListItem, {
      key: index
    }, __jsx("div", {
      className: "heading_five heading-5 no-bar"
    }, __jsx(Anchor, {
      title: listItem.label,
      to: listItem.url,
      hyperlinkType: listItem.hyperlinkType,
      "data-analytics-type": dataAnalyticsSecondaryLinks && dataAnalyticsSecondaryLinks.type ? dataAnalyticsSecondaryLinks.type : '',
      "data-analytics-variation": dataAnalyticsSecondaryLinks && dataAnalyticsSecondaryLinks.variation ? dataAnalyticsSecondaryLinks.variation : '',
      "data-analytics-value": listItem.dataAnalyticsValue ? listItem.dataAnalyticsValue : dataAnalyticsSecondaryLinks && dataAnalyticsSecondaryLinks.value ? dataAnalyticsSecondaryLinks.value : '',
      styleType: "language-link",
      ctaBehavior: listItem.behavior
    }, listItem.label)));
  })), isfooterWithoutForm && socialIconsData && __jsx(SocialIcons, {
    locationName: locationName,
    dataAnalyticsSocialLinks: dataAnalyticsSocialLinks,
    socialIconsData: socialIconsData
  }))));
};
FooterNva.defaultProps = {};
export default styled(FooterNva).withConfig({
  componentId: "sc-1j7wvlx-1"
})(["", ";"], styles);
export { FooterNva as FooterNvaVanilla };