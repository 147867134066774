import { css } from 'styled-components';
import breakPoints from '../../../../themes/breakpoints';
var styles = css(["&.global-header{display:flex;flex-flow:row nowrap;justify-content:flex-start;}.main-container{display:flex;flex-direction:column;@media (min-width:", "){flex-direction:row;&.variation-b{position:relative;}}}.header-container{display:flex;flex-flow:column nowrap;justify-content:center;align-items:center;position:relative;border-bottom:1px solid ", ";@media (min-width:", "){justify-content:space-between;border-bottom:none;}}&.variation-b{.header-container{@media (min-width:", "){position:absolute;left:45%;top:10px;}@media (min-width:", "){top:1px;}}}.navbar-toggler{display:block;position:absolute;left:15px;@media (min-width:", "){display:none;}}.bars{width:30px;display:block;span{border-bottom:2px solid ", ";display:block;margin-bottom:6px;}.center{width:15px;}}.navbar-collapse{display:none;@media (min-width:", "){display:flex;align-items:flex-start;flex-direction:column;width:100%;}}.navbar-nav{display:inline-flex;flex-direction:column;@media (min-width:", "){flex-direction:row;align-items:center;}}&.burger-open{.bars{color:", ";span{display:none;}&:after{content:'X';font-family:", ";font-size:", ";}}.navbar-collapse{display:flex;flex-direction:column;}}"], breakPoints.desktop, function (props) {
  var _props$theme;
  return (_props$theme = props.theme) === null || _props$theme === void 0 ? void 0 : _props$theme.header.borderColor;
}, breakPoints.desktop, breakPoints.desktop, breakPoints.desktopM, breakPoints.desktop, function (props) {
  var _props$theme2;
  return (_props$theme2 = props.theme) === null || _props$theme2 === void 0 ? void 0 : _props$theme2.header.barColor;
}, breakPoints.desktop, breakPoints.desktop, function (props) {
  var _props$theme3;
  return (_props$theme3 = props.theme) === null || _props$theme3 === void 0 ? void 0 : _props$theme3.header.barColor;
}, function (props) {
  var _props$theme4;
  return (_props$theme4 = props.theme) === null || _props$theme4 === void 0 ? void 0 : _props$theme4.fontFamilyIcons;
}, function (props) {
  var _props$theme5;
  return (_props$theme5 = props.theme) === null || _props$theme5 === void 0 ? void 0 : _props$theme5.desktop.fontSizeH2;
});
export default styles;