import _defineProperty from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _toConsumableArray from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
var __jsx = React.createElement;
/**
 * Represents a Location Search REsult item.
 * @author shivam
 */import React, { Fragment } from 'react';
import Link from 'next/link';
import Para from '../../atoms/Para';
import Anchor from '../../atoms/Anchor';
import analyticsConstants from '../../../constants/analyticsConstants'; // todo

// eslint-disable-next-line no-unused-vars
import { LocationFinderLabels } from '../../../constants/labels/en';
import { USstates } from '../../../../src/constants/globalConstants';
import { getAddressURL, getCompleteAddress, convertPhoneFormat } from '../../../utils/utilityFunction';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemPanel, AccordionItemButton } from 'react-accessible-accordion';
var AccordionList = function AccordionList(_ref) {
  var response = _ref.response,
    yextLocationsInformationData = _ref.yextLocationsInformationData,
    customName = _ref.customName;
  var entities = yextLocationsInformationData && Object.values(yextLocationsInformationData);
  var count = entities && entities.length;

  // countryCode - need to group this first - future nva.com
  // sort by name done with a param in api call
  var groupByRegion = !customName && entities && entities.reduce(function (r, a) {
    r[a && a.address && a.address.region] = [].concat(_toConsumableArray(r[a && a.address && a.address.region] || []), [a]);
    return r;
  }, {}) || _defineProperty({}, customName, yextLocationsInformationData);
  var groupByRegionObject = groupByRegion && Object.entries(groupByRegion).sort();
  var convertAddress = function convertAddress(address) {
    var addressString = address && getCompleteAddress(address.line1, address.line2, address.city, address.region, address.postalCode);
    return getAddressURL(addressString);
  };
  var sortArrayOfObjects = function sortArrayOfObjects(array) {
    if (array && array.length > 0) {
      return array.sort(function (a, b) {
        return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
      });
    }
    return array;
  };
  return __jsx(Fragment, null, !customName && __jsx(Para, {
    className: "result-item-count result-item-border container"
  }, count ? "".concat(count, " Locations") : 'Loading...'), groupByRegionObject && groupByRegionObject.map(function (state, key) {
    return __jsx("div", {
      className: "container",
      key: key
    }, __jsx(Accordion, {
      allowZeroExpanded: true
    }, __jsx(AccordionItem, null, __jsx(AccordionItemHeading, null, __jsx(AccordionItemButton, null, USstates[state[0]] || state[0], __jsx("span", {
      className: "heading__icon"
    }))), __jsx(AccordionItemPanel, null, __jsx("div", {
      className: "row locationsInState"
    }, sortArrayOfObjects(state[1]).map(function (location, key) {
      var GMBUrl = location && location.c_googleMyBusinessCID && "https://maps.google.com/maps?cid=".concat(location.c_googleMyBusinessCID);
      return __jsx("div", {
        className: "col-lg-6 col-sm-12",
        key: key
      }, __jsx("h2", {
        className: "heading_four"
      }, location && location.name), location && location.address && location.address.line1 && __jsx("div", {
        className: "address"
      }, __jsx(Link, {
        href: GMBUrl || convertAddress(location.address)
      }, __jsx("a", {
        title: "".concat(location.address.line1, " ").concat(location.address.city, ", ").concat(location.address.region, " ").concat(location.address.postalCode),
        target: "_blank noopener noreferrer",
        "data-analytics-type": analyticsConstants.locationFinder.multiLocationsAccordion.analyticsType,
        "data-analytics-value": analyticsConstants.locationFinder.multiLocationsAccordion.analyticsValue
      }, location.address.line1, ",", ' ', location.address.city, ",", ' ', location.address.region, ' ', location.address.postalCode))), location && location.featuredMessage && location.featuredMessage.description && __jsx("div", {
        className: "featuredMessage"
      }, location.featuredMessage.url ? __jsx(Link, {
        href: location.featuredMessage.url
      }, __jsx("a", {
        title: location.featuredMessage.description,
        target: "_blank noopener noreferrer",
        "data-analytics-type": analyticsConstants.locationFinder.featuredMessage.analyticsType,
        "data-analytics-value": location.featuredMessage.description.split(' ').join('-').toLocaleLowerCase()
      }, location.featuredMessage.description)) : location.featuredMessage.description), location && location.mainPhone && __jsx("div", {
        className: "call"
      }, __jsx("span", {
        className: "call-text"
      }, "Call:"), ' ', __jsx(Link, {
        href: "tel:".concat(convertPhoneFormat(location.c_websitePhone || location.mainPhone))
      }, __jsx("a", {
        title: "Call: ".concat(convertPhoneFormat(location.c_websitePhone || location.mainPhone)),
        style: {
          display: 'inline'
        }
      }, convertPhoneFormat(location.c_websitePhone || location.mainPhone)))), location && location.fax && __jsx("div", {
        className: "fax"
      }, __jsx("span", {
        className: "fax-text"
      }, "Fax:"), ' ', __jsx(Link, {
        href: "tel:".concat(convertPhoneFormat(location.fax))
      }, __jsx("a", {
        title: "Fax: ".concat(convertPhoneFormat(location.fax)),
        style: {
          display: 'inline'
        }
      }, convertPhoneFormat(location.fax)))), __jsx(Anchor, {
        title: "Visit Website",
        to: location && location.websiteUrl && location.websiteUrl.url,
        styleType: "primary-anchor",
        className: "mapListCTA",
        ctaBehavior: "Open in a new page",
        "data-analytics-type": analyticsConstants.locationFinder.locationListCTA.analyticsType,
        "data-analytics-value": analyticsConstants.locationFinder.locationListCTA.analyticsValue
      }, "Visit Website"));
    }))))));
  }));
};
AccordionList.defaultProps = {
  response: {},
  yextLocationsInformationData: {},
  customName: ''
};
export default AccordionList;