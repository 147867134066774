import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["className", "htmlFor", "children"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import styles from './Label.style';
var Label = function Label(_ref) {
  var className = _ref.className,
    htmlFor = _ref.htmlFor,
    children = _ref.children,
    others = _objectWithoutProperties(_ref, _excluded);
  return __jsx("label", _extends({
    className: className,
    htmlFor: htmlFor
  }, others), children);
};
Label.defaultProps = {
  className: ''
};
export default styled(Label).withConfig({
  componentId: "sc-b5xzlo-0"
})(["", ";"], styles);
export { Label as LabelVanilla };