var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import Anchor from '../../../atoms/Anchor';
import Heading from '../../../atoms/Heading';
import Para from '../../../atoms/Para';
import { getAddressURL, getCompleteAddress, parseWorkingHours, convertPhoneFormat } from '../../../../utils/utilityFunction';
import { linkTargetWindow } from '../../../../constants/globalConstants';
import styles from './MultiLocationGrid.style';
var GridItemBlock = styled.section.withConfig({
  componentId: "sc-1s635tk-0"
})(["", ";"], styles);
var GridItem = function GridItem(_ref) {
  var className = _ref.className,
    item = _ref.item,
    cardClassName = _ref.cardClassName,
    dataAnalyticsValue = _ref.dataAnalyticsValue,
    dataAnalyticsTypeCard = _ref.dataAnalyticsTypeCard;
  var name = item.name,
    address = item.address,
    mainPhone = item.mainPhone,
    c_websitePhone = item.c_websitePhone,
    meta = item.meta,
    websiteUrl = item.websiteUrl,
    c_googleMyBusinessCID = item.c_googleMyBusinessCID,
    hours = item.hours,
    c_extendedHours = item.c_extendedHours,
    c_webName = item.c_webName;
  var GMBUrl = c_googleMyBusinessCID && "https://maps.google.com/maps?cid=".concat(c_googleMyBusinessCID);
  var _parseWorkingHours = parseWorkingHours(hours, c_extendedHours),
    perDayHoursDetailArray = _parseWorkingHours.perDayHoursDetailArray,
    nameOfDay = _parseWorkingHours.nameOfDay;
  var addressString = address && getCompleteAddress(address.line1, address.line2, address.city, address.region, address.postalCode);
  var addressStringURL = getAddressURL(addressString);
  var getHoursTextAndColor = function getHoursTextAndColor() {
    var now = new Date().toLocaleDateString('en-US', {
      weekday: 'short'
    });
    var dayIndex = nameOfDay.indexOf(now);
    if (dayIndex != -1 && perDayHoursDetailArray && perDayHoursDetailArray.length == 7) {
      var dayTimings = perDayHoursDetailArray[dayIndex].split(' - ');
      if (dayTimings.length > 1) {
        return ["Open until ".concat(dayTimings[dayTimings.length - 1]), ''];
      } else if (dayTimings.length > 0) {
        var text = dayTimings[dayTimings.length - 1];
        if (text == 'Closed') {
          var dayTimes;
          for (var i = 1; i < 7; i++) {
            dayIndex = dayIndex + 1;
            if (dayIndex == 7) {
              dayIndex = 0;
            }
            dayTimes = perDayHoursDetailArray[dayIndex].split(' - ');
            if (dayTimes.length > 1) {
              return ["Closed, Opens at ".concat(dayTimes[0], " ").concat(nameOfDay[dayIndex]), 'closed'];
            }
            if (dayTimes.length > 0 && dayTimes[0] == 'Open 24 hrs') {
              return ["Closed, Opens on ".concat(nameOfDay[dayIndex], " 24 hours"), 'closed'];
            }
          }
        }
        if (text == 'Open 24 hrs') {
          return [dayTimings[dayTimings.length - 1], ''];
        }
      }
    }
    return ['', ''];
  };
  var hoursText = getHoursTextAndColor();
  return __jsx(GridItemBlock, {
    className: className
  }, __jsx("div", {
    key: meta && meta.id,
    className: cardClassName
  }, __jsx("div", {
    className: "grid-card-body"
  }, websiteUrl && __jsx("div", {
    className: "grid-card-location-name"
  }, __jsx(Heading, {
    HeadingType: "h4"
  }, __jsx(Anchor, {
    title: c_webName ? c_webName : name,
    to: websiteUrl.url
  }, c_webName ? c_webName : name))), addressString && __jsx("div", {
    className: "grid-card-address"
  }, __jsx(Para, null, __jsx(Anchor, {
    title: addressString,
    to: GMBUrl || addressStringURL,
    ctaBehavior: linkTargetWindow.newWindow,
    "data-analytics-type": dataAnalyticsTypeCard || '',
    "data-analytics-value": dataAnalyticsValue || ''
  }, addressString))), c_websitePhone || mainPhone ? __jsx("div", {
    className: "grid-card-phone-number"
  }, __jsx(Para, null, __jsx(Anchor, {
    title: convertPhoneFormat(c_websitePhone || mainPhone),
    href: "tel:".concat(convertPhoneFormat(c_websitePhone || mainPhone)),
    to: "tel:".concat(convertPhoneFormat(c_websitePhone || mainPhone))
  }, convertPhoneFormat(c_websitePhone || mainPhone)))) : null, __jsx("div", {
    className: "hours-helper ".concat(hoursText[1])
  }, __jsx(Para, null, hoursText[0])))), __jsx("div", {
    className: "line-separator"
  }));
};
GridItem.defaultProps = {
  className: '',
  item: {}
};
export default styled(GridItem).withConfig({
  componentId: "sc-1s635tk-1"
})(["", ";"], styles);