import _objectWithoutProperties from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["className", "variation", "imageDetails", "isNvaMainSite", "themeName"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import styles from './Logo.style';
import Anchor from '../../../atoms/Anchor';
import Image from '../../../atoms/Image';
import analyticsConstants from '../../../../constants/analyticsConstants';
import { getSrcSetSettingImg } from './LogoConfig';
var headerAnalytics = analyticsConstants.globalHeader;
var Logo = function Logo(_ref) {
  var _imageDetails$headerL;
  var className = _ref.className,
    variation = _ref.variation,
    imageDetails = _ref.imageDetails,
    isNvaMainSite = _ref.isNvaMainSite,
    themeName = _ref.themeName,
    others = _objectWithoutProperties(_ref, _excluded);
  var contain = 'contain';
  var logoSrcSetSettingImg = getSrcSetSettingImg(variation, themeName === 'Equine', isNvaMainSite);
  return __jsx("div", {
    className: className
  }, __jsx("div", {
    className: "logo-container"
  }, __jsx(Anchor, {
    title: "Logo",
    to: "/",
    "data-analytics-type": headerAnalytics.analyticsType.GLOBAL_NAV,
    "data-analytics-value": headerAnalytics.analyticsValue.GLOBAL_NAV_LOGO,
    "data-analytics-variation": variation
  }, imageDetails && __jsx(Image, {
    className: "header-logo",
    src: imageDetails.headerLogo.url,
    "data-object-fit": contain,
    alt: imageDetails.headerLogo.title || 'Logo',
    title: imageDetails.headerLogo.title || 'Logo',
    srcSetSettingImg: logoSrcSetSettingImg,
    contentType: (_imageDetails$headerL = imageDetails.headerLogo) === null || _imageDetails$headerL === void 0 ? void 0 : _imageDetails$headerL.contentType,
    priority: true,
    imageOptimisationType: 'logo'
  }))));
};
Logo.defaultProps = {
  className: '',
  isNvaMainSite: false
};
export default styled(Logo).withConfig({
  componentId: "sc-62u2kn-0"
})(["", ";"], styles);