var __jsx = React.createElement;
/**
 * Manages typography styles used across theme.
 * @author kkum
 */
import * as React from 'react';
import { createGlobalStyle, withTheme } from 'styled-components';
import breakPoints from './breakpoints';
import mixins from './mixinsFonts';
var Styles = createGlobalStyle(["h1{", "}h2{", "}h3{", "}h4{", "}h5{", "}p{color:", ";font-weight:normal;font-family:", ";font-size:", ";line-height:", ";letter-spacing:", ";}.heading_one{", "}.heading_two{", "}.heading_three{", "}.heading_four,.heading_four > p{", "}.heading_five{", "}.base-color{color:", ";}@media (min-width:", "){p{font-size:", ";line-height:", ";letter-spacing:", ";}h1{", "}h2{", "}h3{", "}h4{", "}h5{", "}.heading_one{", "}.heading_two{", "}.heading_three{", "}.heading_four,.heading_four > p{", "}.heading_five{", "}}"], function (props) {
  return mixins.heading_one(props);
}, function (props) {
  return mixins.heading_two(props);
}, function (props) {
  return mixins.heading_three(props);
}, function (props) {
  return mixins.heading_four(props);
}, function (props) {
  return mixins.heading_five(props);
}, function (props) {
  var _props$theme;
  return (_props$theme = props.theme) === null || _props$theme === void 0 ? void 0 : _props$theme.fontColorP;
}, function (props) {
  var _props$theme2;
  return (_props$theme2 = props.theme) === null || _props$theme2 === void 0 ? void 0 : _props$theme2.fontFamilyP;
}, function (props) {
  var _props$theme3;
  return (_props$theme3 = props.theme) === null || _props$theme3 === void 0 ? void 0 : _props$theme3.fontSizeP;
}, function (props) {
  var _props$theme4;
  return (_props$theme4 = props.theme) === null || _props$theme4 === void 0 ? void 0 : _props$theme4.lineHeightP;
}, function (props) {
  var _props$theme5;
  return (_props$theme5 = props.theme) === null || _props$theme5 === void 0 ? void 0 : _props$theme5.letterSpacingP;
}, function (props) {
  return mixins.heading_one(props);
}, function (props) {
  return mixins.heading_two(props);
}, function (props) {
  return mixins.heading_three(props);
}, function (props) {
  return mixins.heading_four(props);
}, function (props) {
  return mixins.heading_five(props);
}, function (props) {
  var _props$theme6;
  return (_props$theme6 = props.theme) === null || _props$theme6 === void 0 ? void 0 : _props$theme6.fontColorBaseH5;
}, breakPoints.desktop, function (props) {
  var _props$theme7;
  return (_props$theme7 = props.theme) === null || _props$theme7 === void 0 ? void 0 : _props$theme7.desktop.fontSizeP;
}, function (props) {
  var _props$theme8;
  return (_props$theme8 = props.theme) === null || _props$theme8 === void 0 ? void 0 : _props$theme8.desktop.lineHeightP;
}, function (props) {
  var _props$theme9;
  return (_props$theme9 = props.theme) === null || _props$theme9 === void 0 ? void 0 : _props$theme9.desktop.letterSpacingP;
}, function (props) {
  return mixins.heading_one_desktop(props);
}, function (props) {
  return mixins.heading_two_desktop(props);
}, function (props) {
  return mixins.heading_three_desktop(props);
}, function (props) {
  return mixins.heading_four_desktop(props);
}, function (props) {
  return mixins.heading_five_desktop(props);
}, function (props) {
  return mixins.heading_one_desktop(props);
}, function (props) {
  return mixins.heading_two_desktop(props);
}, function (props) {
  return mixins.heading_three_desktop(props);
}, function (props) {
  return mixins.heading_four_desktop(props);
}, function (props) {
  return mixins.heading_five_desktop(props);
});
var Typography = function Typography() {
  return __jsx(Styles, null);
};
export default withTheme(Typography);