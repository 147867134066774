import { css } from 'styled-components';
import breakPoints from '../../../../themes/breakpoints';
import { getFooterTextColorStyle } from '../../../../utils/utilityFunction';
var MultiLocationGridStyles = css([".multi-location-grid{display:grid;width:100%;grid-template-columns:repeat(4,minmax(200px,1fr));grid-gap:35px;justify-content:center;align-items:normal;.line-separator{visibility:hidden;margin:0;}@media (max-width:", "){padding:30px 30px 40px;grid-template-columns:repeat(2,minmax(200px,1fr));}@media (max-width:", "){grid-template-columns:repeat(1,minmax(200px,1fr));grid-gap:0px;.line-separator{visibility:visible;margin:20px 35px;}section:last-child{.line-separator{visibility:hidden;margin:0px;}}}@media all and (-ms-high-contrast:none),(-ms-high-contrast:active){display:-ms-flexbox;flex-wrap:wrap;flex:1 1 auto;}.grid-card-body{text-align:left;.hours-helper{margin:5px;}.grid-card-location-name,.grid-card-address,.grid-card-phone-number{margin:5px;a{color:", ";&:hover{color:", ";}}}.grid-card-location-name{font-weight:bold;h4{font-size:20px;display:unset;}}.grid-card-location-name,.grid-card-address,.grid-card-phone-number,.hours-helper{p{font-size:unset;margin-bottom:0px;color:", ";}a{line-height:1.25;&:hover{text-decoration:underline;}}}@media (min-width:", "){height:200px;}@media (min-width:", ") and (max-width:", "){height:150px;}@media (max-width:", "){display:flex;margin-top:10px;margin-bottom:10px;flex-direction:column;text-align:center;a{text-align:center;}}}}"], breakPoints.tabletL, breakPoints.mobile, function (props) {
  var _props$globalFooterPr, _props$theme;
  return getFooterTextColorStyle(props === null || props === void 0 ? void 0 : (_props$globalFooterPr = props.globalFooterProps) === null || _props$globalFooterPr === void 0 ? void 0 : _props$globalFooterPr.fontColor, props === null || props === void 0 ? void 0 : (_props$theme = props.theme) === null || _props$theme === void 0 ? void 0 : _props$theme.footer, props.theme.footer.textPrimaryLinksColor);
}, function (props) {
  var _props$globalFooterPr2, _props$theme2;
  return getFooterTextColorStyle(props === null || props === void 0 ? void 0 : (_props$globalFooterPr2 = props.globalFooterProps) === null || _props$globalFooterPr2 === void 0 ? void 0 : _props$globalFooterPr2.fontColor, props === null || props === void 0 ? void 0 : (_props$theme2 = props.theme) === null || _props$theme2 === void 0 ? void 0 : _props$theme2.footer, props.theme.footer.textPrimaryLinksHoverColor);
}, function (props) {
  var _props$globalFooterPr3, _props$theme3;
  return getFooterTextColorStyle(props === null || props === void 0 ? void 0 : (_props$globalFooterPr3 = props.globalFooterProps) === null || _props$globalFooterPr3 === void 0 ? void 0 : _props$globalFooterPr3.fontColor, props === null || props === void 0 ? void 0 : (_props$theme3 = props.theme) === null || _props$theme3 === void 0 ? void 0 : _props$theme3.footer, props.theme.footer.textPrimaryLinksColor);
}, breakPoints.desktop, breakPoints.tabletP, breakPoints.tabletL, breakPoints.mobile);
export default MultiLocationGridStyles;