/**
 * @file Manages styles for Header atom.
 * @author kkumari
 */
import { css } from 'styled-components';
import breakPoints from '../../../themes/breakpoints';
export default css(["padding:40px 20px 80px;background-color:", ";.input-field-wrapper{margin-bottom:26px;> h5{margin-top:10px;}}#ohhoneyname,label[for='ohhoneyname']{opacity:0;position:absolute;top:0;left:0;height:0;width:0;z-index:-1;}.form-heading,.success-message,.error-message{text-align:center;width:100%;margin-bottom:30px;@media (min-width:", "){margin-bottom:60px;}}.form-heading{@media (min-width:", "){margin-bottom:50px;}}form{margin:0;}input{border-radius:0;border-top:none;border-right:none;border-left:none;border-color:", ";width:100%;background-color:", ";}textarea{max-width:100%;min-width:100%;max-height:100%;height:200px;border:2px solid ", ";margin-top:15px;background-color:", ";}button{margin-top:30px;border-radius:0;padding:10px 30px;@media (min-width:", "){margin-top:60px;}@media (max-width:", "){", "}}.error-message{color:", ";}.user-name-item{text-indent:100%;white-space:nowrap;overflow:hidden;height:0;}.align-left{float:left;padding-right:0;width:100%;}.contact-form-label{font-size:", ";font-family:", ";}.align-right{float:left;padding-left:0;width:100%;margin-bottom:26px;}.messageBox{width:100%;margin:0;> div{width:100%;}}> div{padding:40px 0;@media (min-width:", "){padding:0;}}@media (min-width:", "){padding:40px 0;}@media (min-width:", "){padding:0 80px;.align-left{float:left;padding-right:10px;width:50%;}.align-right{float:left;padding-left:10px;width:50%;}}"], function (props) {
  var _props$theme;
  return (_props$theme = props.theme) === null || _props$theme === void 0 ? void 0 : _props$theme.form.bgColor;
}, breakPoints.desktop, breakPoints.desktop, function (props) {
  var _props$theme2;
  return (_props$theme2 = props.theme) === null || _props$theme2 === void 0 ? void 0 : _props$theme2.form.borderColor;
}, function (props) {
  var _props$theme3;
  return (_props$theme3 = props.theme) === null || _props$theme3 === void 0 ? void 0 : _props$theme3.form.bgColor;
}, function (props) {
  var _props$theme4;
  return (_props$theme4 = props.theme) === null || _props$theme4 === void 0 ? void 0 : _props$theme4.form.borderColor;
}, function (props) {
  var _props$theme5;
  return (_props$theme5 = props.theme) === null || _props$theme5 === void 0 ? void 0 : _props$theme5.form.bgColor;
}, breakPoints.desktop, breakPoints.mobile, function (props) {
  var _props$theme6;
  return (_props$theme6 = props.theme) !== null && _props$theme6 !== void 0 && _props$theme6.isCorporate ? "margin: 30px auto" : '';
}, function (props) {
  var _props$theme7;
  return (_props$theme7 = props.theme) === null || _props$theme7 === void 0 ? void 0 : _props$theme7.form.errorColor;
}, function (props) {
  var _props$theme8;
  return (_props$theme8 = props.theme) === null || _props$theme8 === void 0 ? void 0 : _props$theme8.desktop.fontSizeP;
}, function (props) {
  var _props$theme9;
  return (_props$theme9 = props.theme) === null || _props$theme9 === void 0 ? void 0 : _props$theme9.fontFamilyP;
}, breakPoints.desktop, breakPoints.tabletP, breakPoints.desktop);