import { css } from 'styled-components';
import breakPoints from '../../../../themes/breakpoints';
var styles = css(["&.global-header{display:flex;flex-flow:row nowrap;justify-content:flex-start;}.main-container{display:flex;flex-direction:column;@media (min-width:", "){flex-direction:row;&.variation-b{position:relative;}}}.header-container{display:flex;flex-flow:column nowrap;justify-content:center;align-items:center;position:relative;border-bottom:1px solid ", ";@media (min-width:", "){justify-content:space-between;border-bottom:none;}}&.variation-b{.header-container{@media (min-width:", "){position:absolute;left:45%;top:10px;}@media (min-width:", "){top:1px;}}}.navbar-toggler{display:block;position:absolute;left:15px;@media (min-width:", "){display:none;}}.bars{width:30px;display:block;span{border-bottom:2px solid ", ";display:block;margin-bottom:6px;}.center{width:15px;}}.navbar-collapse{display:none;@media (min-width:", "){display:flex;align-items:flex-start;flex-direction:column;width:100%;}}.navbar-nav{display:inline-flex;flex-direction:column;@media (min-width:", "){flex-direction:row;align-items:center;}}&.burger-open{.bars{color:", ";span{display:none;}&:after{content:'X';font-family:", ";font-size:", ";}}.navbar-collapse{display:flex;flex-direction:column;}}"], breakPoints.desktop, function (props) {
  return props.theme.header.borderColor;
}, breakPoints.desktop, breakPoints.desktop, breakPoints.desktopM, breakPoints.desktop, function (props) {
  return props.theme.header.barColor;
}, breakPoints.desktop, breakPoints.desktop, function (props) {
  return props.theme.header.barColor;
}, function (props) {
  return props.theme.fontFamilyIcons;
}, function (props) {
  return props.theme.desktop.fontSizeH2;
});
export default styles;