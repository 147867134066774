import { css } from 'styled-components';
import breakPoints from '../../../themes/breakpoints';
var styles = css(["position:absolute;top:0;z-index:7;width:100%;background-color:", ";max-width:1600px;opacity:0.95;padding:43px 20px 20px;margin:0 auto;text-align:center;box-shadow:0 4px 20px 0 rgba(0,0,0,0.14);.close-button{fill:", ";width:18px;height:18px;position:absolute;top:15px;right:15px;background:none;border:none;padding:0;outline:none;}p{color:", ";}.container{& > *{padding:0;margin:0;}}@media (min-width:", "){padding:43px 0 20px;}@media (min-width:", "){padding:27px 0;.close-button{top:50%;transform:translateY(-50%);}}"], function (props) {
  var _props$theme;
  return (_props$theme = props.theme) === null || _props$theme === void 0 ? void 0 : _props$theme.alert.bgColor;
}, function (props) {
  var _props$theme2;
  return (_props$theme2 = props.theme) === null || _props$theme2 === void 0 ? void 0 : _props$theme2.alert.closeBtnColor;
}, function (props) {
  var _props$theme3;
  return (_props$theme3 = props.theme) === null || _props$theme3 === void 0 ? void 0 : _props$theme3.alert.paraColor;
}, breakPoints.tabletP, breakPoints.desktop);
export default styles;