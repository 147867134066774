import { css } from 'styled-components';
import breakPoints from '../../../../themes/breakpoints';
var styles = css(["&.sub-menu{display:none;position:initial;z-index:3;background-color:", ";top:calc(100% + 9px);left:0;padding-top:18px;&.open{display:flex;flex-direction:column;@media (min-width:", "){flex-direction:row;}}@media (min-width:", "){padding-top:0;position:absolute;border-top:1px solid ", ";}@media (min-width:", "){top:calc(100% + 16px);left:0;}}.sub-menu{&-list{padding:0 0 5px;@media (min-width:", "){padding:28px 0;min-width:220px;}}&-heading{color:", ";letter-spacing:", ";line-height:", ";padding:25px 0 18px;@media (min-width:", "){padding:25px 40px 13px;}}&-link{display:block;width:100%;color:", ";letter-spacing:", ";line-height:", ";padding:12px 0;&:hover{text-decoration:underline;}&:last-of-type{padding-bottom:5px;}@media (min-width:", "){padding:12px 40px;}}}"], function (props) {
  var _props$theme;
  return (_props$theme = props.theme) === null || _props$theme === void 0 ? void 0 : _props$theme.header.bgColor;
}, breakPoints.desktop, breakPoints.desktop, function (props) {
  var _props$theme2;
  return (_props$theme2 = props.theme) === null || _props$theme2 === void 0 ? void 0 : _props$theme2.header.borderColor;
}, breakPoints.desktopM, breakPoints.desktop, function (props) {
  var _props$theme3;
  return (_props$theme3 = props.theme) === null || _props$theme3 === void 0 ? void 0 : _props$theme3.header.primaryColor;
}, function (props) {
  var _props$theme4;
  return (_props$theme4 = props.theme) === null || _props$theme4 === void 0 ? void 0 : _props$theme4.letterSpacingSecondaryLink;
}, function (props) {
  var _props$theme5;
  return (_props$theme5 = props.theme) === null || _props$theme5 === void 0 ? void 0 : _props$theme5.lineHeightSecondaryLink;
}, breakPoints.desktop, function (props) {
  var _props$theme6;
  return (_props$theme6 = props.theme) === null || _props$theme6 === void 0 ? void 0 : _props$theme6.header.primaryColor;
}, function (props) {
  var _props$theme7;
  return (_props$theme7 = props.theme) === null || _props$theme7 === void 0 ? void 0 : _props$theme7.letterSpacingPrimaryLink;
}, function (props) {
  var _props$theme8;
  return (_props$theme8 = props.theme) === null || _props$theme8 === void 0 ? void 0 : _props$theme8.lineHeightPrimaryLink;
}, breakPoints.desktop);
export default styles;