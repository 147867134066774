import { css } from 'styled-components';
var styles = css(["", " position:relative;.field{display:flex;flex-flow:column-reverse;}input{height:36px;}label,input{transition:all 0.2s;touch-action:manipulation;}input:placeholder-shown + label{transform-origin:left bottom;transform:translate(0,100%);}input:not(:placeholder-shown) + label,input:focus + label{transform:translate(0,0);cursor:pointer;}.error{color:", ";input{border-color:", ";}svg{height:20px;width:20px;position:absolute;right:0;bottom:24px;path{fill:", ";}}}"], function (props) {
  return props.className == 'input-field-wrapper-oh' ? 'margin-bottom: 0;' : 'margin-bottom: 80px;';
}, function (props) {
  var _props$theme;
  return (_props$theme = props.theme) === null || _props$theme === void 0 ? void 0 : _props$theme.form.errorColor;
}, function (props) {
  var _props$theme2;
  return (_props$theme2 = props.theme) === null || _props$theme2 === void 0 ? void 0 : _props$theme2.form.errorColor;
}, function (props) {
  var _props$theme3;
  return (_props$theme3 = props.theme) === null || _props$theme3 === void 0 ? void 0 : _props$theme3.form.errorColor;
});
export default styles;