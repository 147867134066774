import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["children", "to", "hyperlinkType", "as", "className", "styleType", "noLink", "inheritedStyles", "handleLinkClick", "forceClick", "title", "ctaBehavior"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { buildUrl } from '../../../utils/url';
import { getTargetWindow } from '../../../utils/utilityFunction';
import { linkTargetWindow } from '../../../constants/globalConstants';
import styles from './Anchor.style';
var Anchor = function Anchor(_ref) {
  var children = _ref.children,
    to = _ref.to,
    hyperlinkType = _ref.hyperlinkType,
    as = _ref.as,
    className = _ref.className,
    styleType = _ref.styleType,
    noLink = _ref.noLink,
    inheritedStyles = _ref.inheritedStyles,
    handleLinkClick = _ref.handleLinkClick,
    forceClick = _ref.forceClick,
    title = _ref.title,
    ctaBehavior = _ref.ctaBehavior,
    others = _objectWithoutProperties(_ref, _excluded);
  var href = to;
  var target = getTargetWindow(ctaBehavior);
  var rel = ctaBehavior === linkTargetWindow.newWindow ? 'noopener noreferrer' : undefined;
  if (noLink && to) {
    href = buildUrl(to);
  } else if (forceClick && to) {
    href = to;
  } else if (hyperlinkType && to) {
    if (hyperlinkType === 'Phone') {
      href = "tel:".concat(to);
      rel = undefined;
    } else if (hyperlinkType === 'SMS') {
      href = "sms:".concat(to);
      rel = undefined;
    } else if (hyperlinkType === 'Email') {
      href = "mailto:".concat(to);
      rel = undefined;
    }
  }
  var handleTouchEnd = function handleTouchEnd(e) {
    if (href && e && e.currentTarget) {
      e.currentTarget.href = href;
    }
  };
  return __jsx("a", _extends({
    title: title,
    role: href ? 'link' : 'button',
    href: href,
    className: "".concat(styleType === '' ? '' : "sg-btn-cpnt ".concat(styleType), " ").concat(className),
    onClick: handleLinkClick || handleTouchEnd,
    target: target,
    rel: rel
  }, others), children);
};
Anchor.defaultProps = {
  noLink: false,
  as: '',
  hyperlinkType: 'URL',
  inheritedStyles: '',
  forceClick: false,
  styleType: '',
  ctaBehavior: ''
};
var FakeAnchor = function FakeAnchor(props) {
  return __jsx("span", _extends({}, props, {
    className: "fakeAnchor ".concat(props.className)
  }), props.children);
};
export var FakeAnchorStyled = styled(FakeAnchor).withConfig({
  componentId: "sc-1ux5mjm-0"
})(["", ""], styles);
export default styled(Anchor).withConfig({
  componentId: "sc-1ux5mjm-1"
})(["", ";"], styles);
export { Anchor as AnchorVanilla };