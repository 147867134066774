import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["children", "className", "tabId", "anchorTitle", "index", "isSelected", "onClick", "inheritedStyles"];
var __jsx = React.createElement;
/**
 *
 * Tab
 *
 */
import React from 'react';
import styled from 'styled-components';
import styles from './Tab.style';
import Anchor from '../../../atoms/Anchor';
var Tab = function Tab(_ref) {
  var children = _ref.children,
    className = _ref.className,
    tabId = _ref.tabId,
    anchorTitle = _ref.anchorTitle,
    index = _ref.index,
    isSelected = _ref.isSelected,
    _onClick = _ref.onClick,
    inheritedStyles = _ref.inheritedStyles,
    others = _objectWithoutProperties(_ref, _excluded);
  return __jsx("li", _extends({
    className: className,
    role: "tab",
    "aria-controls": tabId,
    "aria-selected": isSelected,
    id: "tab_".concat(index),
    onClick: function onClick(e) {
      return _onClick(e, index);
    },
    tabIndex: isSelected ? 0 : -1
  }, others), __jsx(Anchor, {
    title: anchorTitle || 'tab-link',
    noLink: true,
    to: "#".concat(tabId),
    className: "tab-link"
  }, children));
};
Tab.defaultProps = {
  className: '',
  inheritedStyles: ''
};
export default styled(Tab).withConfig({
  componentId: "sc-88mbb3-0"
})(["", ";"], styles);
export { Tab as TabVanilla };