var __jsx = React.createElement;
import React from 'react';
import SocialIcon from '../../atoms/SocialIcon';
import componentAnalytics from '../../../constants/analyticsConstants';
import styled from 'styled-components';
import styles from './SocialIcons.style';
var SocialIconBlock = styled.section.withConfig({
  componentId: "sc-1lai8ft-0"
})(["", ";"], styles);
var SocialIcons = function SocialIcons(_ref) {
  var locationName = _ref.locationName,
    socialIconsData = _ref.socialIconsData,
    dataAnalyticsSocialLinks = _ref.dataAnalyticsSocialLinks,
    styleType = _ref.styleType;
  var facebookUrl = socialIconsData.facebookUrl,
    twitterUrl = socialIconsData.twitterUrl,
    instagramUrl = socialIconsData.instagramUrl,
    linkedInUrl = socialIconsData.linkedInUrl,
    youTubeUrl = socialIconsData.youTubeUrl;
  var dataAnalyticsSocial = dataAnalyticsSocialLinks;
  return __jsx(SocialIconBlock, {
    className: "social-icons"
  }, facebookUrl && __jsx(SocialIcon, {
    dataAnalyticsType: "icon-social-feed",
    locationName: locationName,
    dataAnalyticsValue: componentAnalytics.sociallinks.analyticsValue.SOCIAL_LINK_FACEBOOK,
    dataAnalyticsVariation: dataAnalyticsSocial && dataAnalyticsSocial.variation ? dataAnalyticsSocial.variation : '',
    socialIconType: componentAnalytics.sociallinks.analyticsValue.SOCIAL_LINK_FACEBOOK,
    socialUrl: facebookUrl
  }), twitterUrl && __jsx(SocialIcon, {
    dataAnalyticsType: "icon-social-feed",
    locationName: locationName,
    dataAnalyticsValue: componentAnalytics.sociallinks.analyticsValue.SOCIAL_LINK_TWITTER,
    dataAnalyticsVariation: dataAnalyticsSocial && dataAnalyticsSocial.variation ? dataAnalyticsSocial.variation : '',
    socialIconType: componentAnalytics.sociallinks.analyticsValue.SOCIAL_LINK_TWITTER,
    socialUrl: twitterUrl
  }), instagramUrl && __jsx(SocialIcon, {
    dataAnalyticsType: "icon-social-feed",
    locationName: locationName,
    dataAnalyticsValue: componentAnalytics.sociallinks.analyticsValue.SOCIAL_LINK_INSTAGRAM,
    dataAnalyticsVariation: dataAnalyticsSocial && dataAnalyticsSocial.variation ? dataAnalyticsSocial.variation : '',
    socialIconType: componentAnalytics.sociallinks.analyticsValue.SOCIAL_LINK_INSTAGRAM,
    socialUrl: instagramUrl
  }), linkedInUrl && __jsx(SocialIcon, {
    dataAnalyticsType: "icon-social-feed",
    locationName: locationName,
    dataAnalyticsValue: componentAnalytics.sociallinks.analyticsValue.SOCIAL_LINK_LINKEDIN,
    dataAnalyticsVariation: dataAnalyticsSocial && dataAnalyticsSocial.variation ? dataAnalyticsSocial.variation : '',
    socialIconType: componentAnalytics.sociallinks.analyticsValue.SOCIAL_LINK_LINKEDIN,
    socialUrl: linkedInUrl
  }), youTubeUrl && __jsx(SocialIcon, {
    dataAnalyticsType: "icon-social-feed",
    locationName: locationName,
    dataAnalyticsValue: componentAnalytics.sociallinks.analyticsValue.SOCIAL_LINK_YOUTUBE,
    dataAnalyticsVariation: dataAnalyticsSocial && dataAnalyticsSocial.variation ? dataAnalyticsSocial.variation : '',
    socialIconType: componentAnalytics.sociallinks.analyticsValue.SOCIAL_LINK_YOUTUBE,
    socialUrl: youTubeUrl
  }));
};
SocialIcons.defaultProps = {
  socialIconsData: '',
  dataAnalyticsSocialLinks: '',
  styleType: ''
};
export default SocialIcons;