/**
 * @file Manages styles for Header atom.
 * @author kkumari
 */
import { css } from 'styled-components';
import breakPoints from '../../../themes/breakpoints';
export default css(["font-family:", ";font-size:", ";line-height:", ";letter-spacing:", ";color:", ";@media (min-width:", "){font-size:", ";line-height:", ";letter-spacing:", ";}"], function (props) {
  var _props$theme;
  return (_props$theme = props.theme) === null || _props$theme === void 0 ? void 0 : _props$theme.fontFamilyH4;
}, function (props) {
  var _props$theme2;
  return (_props$theme2 = props.theme) === null || _props$theme2 === void 0 ? void 0 : _props$theme2.fontSizeH4;
}, function (props) {
  var _props$theme3;
  return (_props$theme3 = props.theme) === null || _props$theme3 === void 0 ? void 0 : _props$theme3.lineHeightH4;
}, function (props) {
  var _props$theme4;
  return (_props$theme4 = props.theme) === null || _props$theme4 === void 0 ? void 0 : _props$theme4.letterSpacingH4;
}, function (props) {
  var _props$theme5, _props$theme5$form;
  return (_props$theme5 = props.theme) === null || _props$theme5 === void 0 ? void 0 : (_props$theme5$form = _props$theme5.form) === null || _props$theme5$form === void 0 ? void 0 : _props$theme5$form.labelColor;
}, breakPoints.desktop, function (props) {
  var _props$theme6, _props$theme6$desktop;
  return (_props$theme6 = props.theme) === null || _props$theme6 === void 0 ? void 0 : (_props$theme6$desktop = _props$theme6.desktop) === null || _props$theme6$desktop === void 0 ? void 0 : _props$theme6$desktop.fontSizeH4;
}, function (props) {
  var _props$theme7, _props$theme7$desktop;
  return (_props$theme7 = props.theme) === null || _props$theme7 === void 0 ? void 0 : (_props$theme7$desktop = _props$theme7.desktop) === null || _props$theme7$desktop === void 0 ? void 0 : _props$theme7$desktop.lineHeightH4;
}, function (props) {
  var _props$theme8, _props$theme8$desktop;
  return (_props$theme8 = props.theme) === null || _props$theme8 === void 0 ? void 0 : (_props$theme8$desktop = _props$theme8.desktop) === null || _props$theme8$desktop === void 0 ? void 0 : _props$theme8$desktop.letterSpacingH4;
});