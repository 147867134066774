import _objectWithoutProperties from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["className", "globalHeaderData", "yextContactInformationData", "isNvaMainSite", "headerLogo"];
var __jsx = React.createElement;
/**
 * Represents Global Header for Masterbrand business type.
 * @author gsin24
 */import React from 'react';
import styled from 'styled-components';
import styles from './MasterbrandHeader.style';
import Header from '../../../molecules/Header';
var MasterbrandHeader = function MasterbrandHeader(_ref) {
  var className = _ref.className,
    globalHeaderData = _ref.globalHeaderData,
    yextContactInformationData = _ref.yextContactInformationData,
    isNvaMainSite = _ref.isNvaMainSite,
    headerLogo = _ref.headerLogo,
    others = _objectWithoutProperties(_ref, _excluded);
  if (!globalHeaderData) {
    return null;
  }
  var imageDetails = {
    headerLogo: headerLogo
  };
  return __jsx(Header, {
    globalHeaderData: globalHeaderData,
    yextContactInformationData: yextContactInformationData,
    imageDetails: imageDetails,
    isNvaMainSite: isNvaMainSite
  });
};
MasterbrandHeader.defaultProps = {
  className: '',
  isNvaMainSite: false
};
export default styled(MasterbrandHeader).withConfig({
  componentId: "sc-7jehnd-0"
})(["", ";"], styles);
export { MasterbrandHeader as MasterbrandHeaderVanilla };